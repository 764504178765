import { useEffect, useRef, useState } from 'react';
import './Skeleton.css';

const Skeleton = ({ rowHeight, margin = 10, padding = 20 }) => {
  const [rowCount, setRowCount] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const calculateRows = () => {
      if (containerRef.current) {
        const availableHeight = containerRef.current.clientHeight - margin * 2;
        const calculatedRows = Math.floor(availableHeight / rowHeight);
        setRowCount(calculatedRows);
      }
    };
    calculateRows();
    const resizeObserver = new ResizeObserver(calculateRows);
    resizeObserver.observe(containerRef.current);

    return () => resizeObserver.disconnect();
  }, [rowHeight, margin]);

  return (
    <div
      ref={containerRef}
      style={{ overflow: 'hidden', height: '100%', width: '100%', padding: `0 ${padding}px` }}
    >
      {Array.from({ length: rowCount }).map((_, index) => (
        <div
          key={`skeleton-row-${index}`}
          style={{ height: rowHeight, margin: `${margin}px 0` }}
          className='rect skeleton-content'
        />
      ))}
    </div>
  );
};

export default Skeleton;
