import './VaultIcon.css';
import generalVaultIcon from '../../assets/icons/general-vault-icon.svg';
import smartVaultIcon from '../../assets/icons/smart-vault-icon.svg';
import automationVaultIcon from '../../assets/icons/automation-vault-icon.svg';
import { vaultTypes } from '../../helpers/constants';
import { Suspense, lazy } from 'react';
const VTooltip = lazy(() => import('../VTooltip/VTooltip'));

const VaultIcon = ({
  name,
  color,
  id,
  vaultType,
  className,
  tooltipClassName,
  secondaryIconClassName,
  ...rest
}) => {
  const successWalletSecondaryIcon = (vaultType) => {
    switch (vaultType?.toLowerCase()) {
      case vaultTypes.GENERAL:
        return generalVaultIcon;
      case vaultTypes.SMART:
        return smartVaultIcon;
      case vaultTypes.AUTOMATION:
        return automationVaultIcon;
      default:
        break;
    }
  };

  return (
    <div
      className={className ? className : 'vault-icon-component'}
      data-tooltip-id={`vault-icon-component-${id}`}
      data-tooltip-content={vaultType}
      style={{ backgroundColor: color || '#000' }}
      {...rest}
    >
      {name?.charAt(0)?.toUpperCase()}
      <Suspense fallback={null}>
        <VTooltip id={`vault-icon-component-${id}`} className={tooltipClassName} place='top' />
      </Suspense>
      {vaultType && (
        <img
          className={secondaryIconClassName ? secondaryIconClassName : 'vault-icon-component-secondary-icon'}
          src={successWalletSecondaryIcon(vaultType)}
        />
      )}
    </div>
  );
};

export default VaultIcon;
