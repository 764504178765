import { T } from '../../../T';
import './AutomationRulesTable.css';
import { automationRuleStatuses } from '../../../../helpers/transactionrequests/statuses';
import AutomationRuleRow from './AutomationRuleRow';
import { useSelector } from 'react-redux';
import Skeleton from '../../../Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';

// Type can be "address", "vaults", "assets"
const AutomationRulesTable = ({ data, loading, handleEditRule, handleDeleteRule }) => {
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  return (
    <div>
      <div className={`automation-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='automation-table-wrapper'>
          <div className='name'>
            <T>app.name</T>
          </div>
          <div className='asset'>
            <T>app.assets</T>
          </div>
          <div className='asset'>
            <T>app.trigger</T>
          </div>
          <div className='fee-priority'>
            <T>app.destination</T>
          </div>
          <div className='status'>
            <T>app.status</T>
          </div>
          <div className='settings' />
        </div>
        <div className='accordions-parent'>
          {loading && (
            <div className='loading-full-height-developers'>
              <Skeleton rowHeight={isMobile ? 350 : 119} padding={isMobile ? 20 : 50} />
            </div>
          )}
          {!loading &&
            data?.map((rule) => {
              const statusObj = automationRuleStatuses?.find(
                (status) => status?.value === rule?.status.toUpperCase(),
              );
              return (
                <AutomationRuleRow
                  rule={rule}
                  key={rule?.id}
                  status={statusObj}
                  handleEdit={handleEditRule}
                  handleDelete={handleDeleteRule}
                />
              );
            })}
          {!loading && data?.length === 0 && (
            <div className='assets-table-no-assets'>
              <div>
                <T>app.no.automation.rules.found</T>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutomationRulesTable;
