import { Suspense, lazy, useEffect, useState } from 'react';
import { T, t } from '../../T';
import CopyButton from '../../CopyButton/CopyButton';
import { getWalletApi } from '../../../api/endpoints';
import { useSelector } from 'react-redux';
import Button from '../../Button/Button';
const VTooltip = lazy(() => import('../../VTooltip/VTooltip'));
const WalletBackUpModal = lazy(() => import('../../Modals/WalletBackUpModal'));

const WalletSettingsTable = ({ id, editData, setBeckUp }) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [openWalletBackUpModal, setOpenWalletBackUpModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vaultodyDateFunc, setVaultodyDateFunc] = useState();
  const walletBackupPermission = myProfile.premmisions?.includes('WALLETS_BACKUP');

  const getDataSettings = () => {
    getWalletApi(id)
      .then((res) => {
        if (res?.data?.item) {
          setData(res.data.item);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDataSettings();
    const loadVaultodyDate = async () => {
      const module = await import('../../../helpers/date');
      setVaultodyDateFunc(() => module.vaultodyDate);
    };
    loadVaultodyDate();
  }, []);

  useEffect(() => {
    getDataSettings();
  }, [openWalletBackUpModal]);

  return (
    <div className='wallet-settings'>
      <div className={`table wallet-settings-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='table-content'>
          <div className='table-row data wallet'>
            <div className='wallet-settings-wrapper'>
              <div className='titles-box id-wallet'>
                <div className='titles-box-title'>
                  <T>app.walled.id</T>
                </div>
                <div className='titles-box-sub'>
                  {id} <CopyButton element={id} />{' '}
                </div>
              </div>
            </div>
          </div>
          <div className='table-row data wallet'>
            <div className='wallet-settings-wrapper'>
              <div className='titles-box id-wallet'>
                <div className='titles-box-title'>
                  <T>app.wallet.backup</T>
                </div>
                <div className='titles-box-sub'>
                  <T>app.backing.up.a.wallet</T>
                </div>
              </div>
              {!loading && (
                <div className='button-holder'>
                  {data?.settings?.backup?.status === 'done' ? (
                    `Backup ${vaultodyDateFunc ? vaultodyDateFunc(data?.settings?.backup?.at, 'full') : null}`
                  ) : (
                    <Button
                      disabled={!walletBackupPermission}
                      size='sm'
                      variant='dark'
                      onClick={() => setOpenWalletBackUpModal(true)}
                      data-tooltip-id='backup-button'
                      data-tooltip-content={t(lng, 'app.role.limitation')}
                    >
                      {!walletBackupPermission && (
                        <Suspense fallback={null}>
                          <VTooltip id='backup-button' />
                        </Suspense>
                      )}
                      <T>app.wallet.backup</T>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openWalletBackUpModal && (
        <Suspense fallback={null}>
          <WalletBackUpModal
            setBeckUp={setBeckUp}
            backupData={data?.settings?.backup}
            toggleFunction={setOpenWalletBackUpModal}
            editData={editData}
            id={id}
          />
        </Suspense>
      )}
    </div>
  );
};
export default WalletSettingsTable;
