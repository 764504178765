import { Suspense, lazy } from 'react';
import './Textfield.css';
const VTooltip = lazy(() => import('../../components/VTooltip/VTooltip'));

const Textfield = ({
  id,
  type,
  placeholder,
  value,
  onChange,
  name,
  fullWidth,
  icon,
  error,
  disableFocusShadowEffect,
  size = 'sm', // sm || lg
  showValidationEndIcons, // For the check and error end icons when validating the textfield
  trim, // Trims the input if true
  variant = 'textfield', // 'textfield', 'textarea'
  errorTooltipProps,
  ...rest
}) => {
  return (
    <div
      data-tooltip-id={`textfield-error-validation-${id}`}
      data-tooltip-content={error}
      data-tooltip-variant='error'
      className={`textfield-component-wrapper ${error ? 'error' : value ? 'valid' : ''} ${
        fullWidth ? 'fullWidth' : ''
      }`}
    >
      {icon && <div className='textfield-icon'>{icon}</div>}
      {variant === 'textfield' && (
        <input
          name={name}
          type={type}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            if (trim && !!value) {
              e.target.value = e.target.value?.trim();
              onChange(e);
            }
          }}
          placeholder={placeholder}
          {...rest}
          className={`textfield-component-main form-control ${disableFocusShadowEffect ? 'no-focus' : ''} ${
            (error && showValidationEndIcons) || (value && value?.includes('<script>'))
              ? 'error'
              : value && showValidationEndIcons
                ? 'valid'
                : ''
          } ${fullWidth ? 'fullWidth' : ''} ${icon ? 'adornment' : ''} ${size}`}
        />
      )}
      {variant === 'textarea' && (
        <textarea
          name={name}
          type={type}
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            if (trim && !!value) {
              e.target.value = e.target.value?.trim();
              onChange(e);
            }
          }}
          placeholder={placeholder}
          {...rest}
          className={`textfield-component-main form-control ${disableFocusShadowEffect ? 'no-focus' : ''} ${
            (error && showValidationEndIcons) || (value && value?.includes('<script>'))
              ? 'error'
              : value && showValidationEndIcons
                ? 'valid'
                : ''
          } ${fullWidth ? 'fullWidth' : ''} ${icon ? 'adornment' : ''} ${size}`}
        />
      )}
      {error && (
        <Suspense fallback={null}>
          <VTooltip
            style={{ width: '100%', zIndex: '9999999' }}
            isOpen={true}
            place='bottom'
            effect='solid'
            id={`textfield-error-validation-${id}`}
            {...errorTooltipProps}
          />
        </Suspense>
      )}
    </div>
  );
};

export default Textfield;
