import { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import Textfield from '../../components/Textfield/Textfield';
import Button from '../../components/Button/Button';
import { T, t } from '../../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import ContactRow from '../../components/Pages/Contacts/ContactRow/ContactRow';
import './Contacts.css';
import { getContactsApi, getContactsByNameSearchApi } from '../../api/endpoints';
import useDebounce from '../../helpers/hooks/useDebounce';
import LoadMore from '../../components/LoadMore/LoadMore';
import { setPage } from '../../redux/slices/globalSlice';
import Filter from '../../components/Filter/Filter';
import { unique } from '../../helpers/array';
import { getCryptoIconFromBucket } from '../../helpers/protocolIcons/protocolIcons';
import { setMyUsage } from '../../redux/slices/userSlice';
import { checkLimits } from '../../helpers/limits';
import Skeleton from '../../components/Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
const VTooltip = lazy(() => import('../../components/VTooltip/VTooltip'));
const LimitsModal = lazy(() => import('../../components/Modals/LimitsModal'));
const ContactAddNewModal = lazy(
  () => import('../../components/Pages/Contacts/ContactAddNewModal/ContactAddNewModal'),
);
const ContactCreateSuccessModal = lazy(
  () => import('../../components/Pages/Contacts/ContactCreateSuccessModal/ContactCreateSuccessModal'),
);

const Contacts = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const myUsage = useSelector((state) => state.userReducer.myUsage);
  const myPackage = useSelector((state) => state.userReducer.myPackage);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const [searchText, setSearchText] = useState('');
  const [createdContact, setCreatedContact] = useState({ open: false });
  const [selectedProtocols, setSelectedProtocols] = useState();
  const [addNewContactModal, setAddNewContactModal] = useState(false);
  const [contacts, setContacts] = useState();
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const createContactPermission = myProfile.premmisions?.includes('CONTACTS_CREATE');

  const protocolsWithIcons = useMemo(
    () =>
      unique(protocols, 'blockchain').map((protocol) => ({
        ...protocol,
        icon: getCryptoIconFromBucket(protocol?.currency?.toLowerCase()),
      })),
    [protocols],
  );

  const handleProtocolsFilterChange = (selectedProtocols) => {
    if (selectedProtocols?.length === protocolsWithIcons?.length) {
      setSelectedProtocols(undefined);
    } else {
      setSelectedProtocols(unique(selectedProtocols, 'blockchain'));
    }
  };

  const getContactsBySearching = async (startingAfterId, isLoadMore) => {
    if (isLoadMore) {
      setLoadMoreLoading(true);
    } else {
      setLoading(true);
    }
    return getContactsByNameSearchApi(typeUser, {
      params: {
        item: searchText,
        limit: 10,
        ...(selectedProtocols?.[0]?.blockchain ? { blockchain: selectedProtocols?.[0]?.blockchain } : {}),
        ...(startingAfterId ? { startingAfter: startingAfterId } : {}),
      },
    })
      .then((res) => {
        setContacts(startingAfterId ? (prev) => [...prev, ...res?.data?.items] : res.data?.items);
        setLoadMoreVisible(JSON.parse(res?.data?.hasMore) || false);
      })
      .finally(() => (isLoadMore ? setLoadMoreLoading(false) : setLoading(false)));
  };

  const getContacts = async (startingAfterId, isLoadMore) => {
    if (isLoadMore) {
      setLoadMoreLoading(true);
    } else {
      setLoading(true);
    }
    return getContactsApi(typeUser, selectedProtocols?.[0]?.blockchain, {
      params: { limit: 10, ...(startingAfterId ? { startingAfter: startingAfterId } : {}) },
    })
      .then((res) => {
        setContacts(startingAfterId ? (prev) => [...prev, ...res?.data?.items] : res.data?.items);
        setLoadMoreVisible(JSON.parse(res?.data?.hasMore) || false);
      })
      .finally(() => (isLoadMore ? setLoadMoreLoading(false) : setLoading(false)));
  };

  const loadMoreHandler = async () => {
    const lastRecord = contacts[contacts.length - 1];
    if (searchText?.length > 2) {
      getContactsBySearching(lastRecord?.id, true);
    } else {
      getContacts(lastRecord?.id, true);
    }
  };

  useDebounce(
    () => {
      if (searchText?.length > 2) {
        getContactsBySearching();
        setSelectedProtocols(undefined);
      } else {
        getContacts();
      }
    },
    500,
    [searchText],
  );

  useEffect(() => {
    if (typeUser) {
      setSearchText('');
      setSelectedProtocols(undefined);
    }
  }, [typeUser]);

  useEffect(() => {
    if (typeUser) {
      if (searchText?.length > 2) {
        getContactsBySearching();
      } else {
        getContacts();
      }
    }
  }, [typeUser, selectedProtocols]);

  useEffect(() => {
    dispatch(setPage(t(lng, 'app.contacts')));
  }, []);

  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (
        messageSocket?.data?.event === 'create_contact_approved' ||
        messageSocket?.data?.event === 'create_contact_rejected'
      ) {
        setSelectedProtocols(undefined);
        getContacts();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
        setSearchText('');
        setSelectedProtocols(undefined);
      }
    }
  }, [messageSocket]);

  return (
    <div className='contacts-page'>
      {limitModal && (
        <Suspense fallback={null}>
          <LimitsModal toggleFunction={setLimitModal} modalName='app.contacts.limit.reached' />
        </Suspense>
      )}
      {createdContact?.open && (
        <Suspense fallback={null}>
          <ContactCreateSuccessModal
            displayAddAnotherButton
            address={createdContact?.address}
            addressName={createdContact?.name}
            blockchain={createdContact?.blockchain}
            addCallback={() => setAddNewContactModal(true)}
            closeCallback={() => {
              setCreatedContact({ open: false });
              setSelectedProtocols(undefined);
              getContacts();
            }}
          />
        </Suspense>
      )}
      {addNewContactModal && (
        <Suspense fallback={null}>
          <ContactAddNewModal
            closeCallback={() => {
              setAddNewContactModal(false);
            }}
            callback={(createdContact) => {
              setAddNewContactModal(false);
              setCreatedContact({ open: true, ...createdContact });
              checkLimits((usage) => dispatch(setMyUsage(usage)));
            }}
          />
        </Suspense>
      )}
      <div className='contacts-page-bar'>
        <div className='contacts-page-search-wrapper'>
          <Textfield
            type='text'
            fullWidth
            icon={<SearchIcon />}
            placeholder={t(lng, 'app.search.contact')} // 'Search by address name or address'
            size='lg'
            value={searchText}
            onChange={(e) => setSearchText(e.currentTarget.value)}
          />
        </div>
        <div className='contacts-page-blockchain-filter'>
          <Filter
            items={protocolsWithIcons}
            placeholder='app.filters.blockchains'
            selectAllField
            displayIcon
            roundedIcon
            customIconKey='icon'
            onChange={handleProtocolsFilterChange}
            selectedItems={selectedProtocols || []}
            dataKey='blockchain'
            dataNameKey='publicName'
          />
        </div>
        <div className='contacts-page-button-holder'>
          <Button
            size='md'
            data-tooltip-id='create-contact'
            data-tooltip-content={t(lng, 'app.role.limitation')}
            onClick={() => {
              if (myUsage?.contactsCount >= myPackage?.constraints?.waasContactsCount) {
                setLimitModal(true);
              } else {
                setAddNewContactModal(true);
              }
            }}
            disabled={!createContactPermission}
          >
            {!createContactPermission && (
              <Suspense fallback={null}>
                <VTooltip id='create-contact' />
              </Suspense>
            )}
            <ContactsIcon
              className={`contacts-page-contacts-icon ${!createContactPermission ? 'inactive' : ''}`}
            />
            <T>app.add.contact</T>
          </Button>
        </div>
      </div>
      <div className={`contacts-table-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='contacts-table'>
          <div className='contact-page-header'>
            <div className='contact-row-address-name-header'>
              <T>app.contact.name</T>
            </div>
            <div className='contact-row-blockchain-header'>
              <T>app.blockchain</T>
            </div>
            <div className='contact-row-address-header'>
              <T>app.address</T>
            </div>
            <div className='contact-row-status-header'>
              <T>app.status</T>
            </div>
          </div>
          {loading && (
            <div className='loading-full-height-contacts'>
              <Skeleton rowHeight={isMobile ? 284 : 80} padding={isMobile ? 20 : 50} />
            </div>
          )}
          {!loading && contacts && contacts?.length > 0 && (
            <div className='contacts-page-contact-row-wrapper'>
              {contacts?.map((contact) => (
                <ContactRow
                  key={contact?.id}
                  id={contact?.id}
                  address={contact?.address}
                  addressName={contact?.name}
                  blockchain={contact?.blockchain}
                  status={contact?.status?.toUpperCase()}
                  deleteCallback={async () => {
                    setSelectedProtocols(undefined);
                    await getContacts();
                    checkLimits((usage) => dispatch(setMyUsage(usage)));
                  }}
                />
              ))}
              {!loading && loadMoreVisible && (
                <div style={{ marginBottom: 30 }}>
                  <LoadMore isLoading={loadMoreLoading} loadMoreHandler={loadMoreHandler} />
                </div>
              )}
            </div>
          )}
          {!loading && contacts && contacts?.length <= 0 && (
            <div className='contacts-page-no-contacts'>
              {searchText?.length > 2 ? (
                <T>app.contacts.no.contacts.search</T>
              ) : (
                <T>app.contacts.no.contacts</T>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Contacts;
