import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Outlet, Navigate } from 'react-router-dom';
import {
  getBlockchainProtocolsApi,
  getPackageInfoApi,
  getProfileCurrentUsageApi,
  getProfileInfoApi,
  getVerificationDataApi,
} from '../api/endpoints';
import {
  setLimits,
  clientStatuses,
  setMyPackage,
  setMyProfile,
  setMyUsage,
  setVerificationInfo,
} from '../redux/slices/userSlice';
import { setProtocols, setTrialBannerVisible, setSuspendedModalVisible } from '../redux/slices/globalSlice';
import { STATE_DASHBOARD, logoutUser } from '../redux/slices/authSlice';
import {
  ROUTE_CONTACTS,
  ROUTE_HOME,
  ROUTE_TEAM,
  ROUTE_TRX_POLICY,
  ROUTE_WALLET,
  ROUTE_WALLETS,
} from './routes';
import DashboardLayout from '../components/DashboardLayout/DashboardLayout';
import Tabs from '../components/Tabs/Tabs';
import { T } from '../components/T';
import useViewPermissions from '../helpers/hooks/useViewPermissions';

const DashboardRoute = ({ isAllowed, redirectPath = ROUTE_HOME }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const userId = useSelector((state) => state.userReducer.myPackage?.userId);
  const location = useLocation();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myPackage = useSelector((state) => state.userReducer.myPackage);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const myUsage = useSelector((state) => state.userReducer.myUsage);
  const [activeTab, setActiveTab] = useState();
  const { pageTabs, isPageViewPermissionMissing, isTabViewPermissionMissing } =
    useViewPermissions(setActiveTab);

  useEffect(() => {
    if (auth.state === STATE_DASHBOARD) {
      getPackageInfoApi().then((res) => dispatch(setMyPackage(res.data.item)));
      getProfileInfoApi().then((res) => {
        const data = Object.assign(res.data.item, {
          premmisions: Object.values(res.data.item.permissions).map((i) => {
            return i.permissionName;
          }),
        });
        dispatch(setMyProfile(data));
      });
    }
  }, [auth.state]);

  useEffect(() => {
    dispatch(
      setLimits({
        [ROUTE_WALLETS]: {
          from: typeUser === 'main' ? myUsage?.mainWalletsCount : myUsage?.testWalletsCount,
          to:
            typeUser === 'main'
              ? myPackage?.constraints?.mainWalletsCount
              : myPackage?.constraints?.testWalletsCount,
        },
        [ROUTE_WALLET]: {
          from: myUsage?.addressesCount,
          to: myPackage?.constraints?.walletAddressesCount,
        },
        [ROUTE_TRX_POLICY]: {
          from: myUsage?.currentRulesCount,
          to: myPackage?.constraints?.rulesCount,
        },
        [ROUTE_TEAM]: {
          from: myUsage?.currentTeamMembersCount,
          to: myPackage?.constraints?.teamMembersCount,
        },
        [ROUTE_CONTACTS]: {
          from: myUsage?.contactsCount,
          to: myPackage?.constraints?.waasContactsCount,
        },
      }),
    );
  }, [typeUser, myPackage, myUsage]);

  useEffect(() => {
    if (messageSocket?.data?.event) {
      const data = messageSocket?.data;
      if (data?.event === 'reset_user_device_approved' && userId === data?.item?.userId) {
        localStorage.clear();
        dispatch(logoutUser());
        window.location.replace(ROUTE_HOME);
      }
    }
  }, [messageSocket]);

  useEffect(() => {
    getBlockchainProtocolsApi().then((res) => dispatch(setProtocols(res.data.items)));
    getPackageInfoApi().then((res) => dispatch(setMyPackage(res.data.item)));
    getProfileCurrentUsageApi().then((res) => dispatch(setMyUsage(res.data.item)));
    getProfileInfoApi().then((res) => {
      const data = Object.assign(res.data.item, {
        premmisions: Object.values(res.data.item.permissions).map((i) => {
          return i.permissionName;
        }),
      });
      dispatch(setMyProfile(data));
    });
    getVerificationDataApi()
      .then((res) => dispatch(setVerificationInfo(res?.data?.item)))
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL ||
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL
    ) {
      dispatch(setTrialBannerVisible(true));
    }
    if (
      userVerificationInfo?.state?.toUpperCase() === clientStatuses.SUSPENDED
      // && window.location.pathname !== '/pricing-table'
    ) {
      dispatch(setSuspendedModalVisible(true));
    }
  }, [location, userVerificationInfo]);

  return !isAllowed ? (
    <Navigate to={redirectPath} replace />
  ) : (
    <DashboardLayout>
      {pageTabs && (
        <div className='dashboard-layout-tabs'>
          <Tabs value={activeTab} onChange={(tab) => setActiveTab(tab)} items={pageTabs} />
        </div>
      )}
      {isPageViewPermissionMissing && (
        <div className='table governance-layer-table'>
          <div className='table-row no-active no-prem'>
            <T>app.role.limitation</T>
          </div>
        </div>
      )}
      {userVerificationInfo?.state?.toUpperCase() === clientStatuses.SUSPENDED
        ? null
        : myProfile?.premmisions &&
          isPageViewPermissionMissing === false && (
            <Outlet context={[activeTab, isTabViewPermissionMissing, pageTabs]} />
          )}
    </DashboardLayout>
  );
};

export default DashboardRoute;
