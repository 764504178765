export const BASE_URL = (process.env.REACT_APP_BACKEND_HOST ?? '').replace(/\/$/, '');
export const HOST = BASE_URL + '/api';
export const DEFAULT_LANGUAGE = 'en';
export const isAppUnderMaintanance = false;
export const whitelistedIpAddresses = ['212.95.176.130', '192.168.0.105'];
export const APP_APPLE_STORE_URL = 'https://apps.apple.com/bg/app/vaultody-approver/id6446577538';
export const APP_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.vaultody.approver';

export const LOGIN_STATUS = {
  SUCCESS: 'Success',
  FAILED: 'Failed',
};

export const bookCallLink = 'https://zcal.co/teodor.todorov/partnerships';
