import { useDispatch, useSelector } from 'react-redux';
import './DashboardLayout.css';
import '../../pages/DashboardHome/DashboardHome.css';
import TitleBox from '../Pages/TitleBox/TitleBox';
import Header from '../Pages/Header/Header';
import DashboardMenu from '../DashboardMenu/DashboardMenu';
import { Suspense, lazy, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { clientStatuses } from '../../redux/slices/userSlice';
import { ROUTE_HOME } from '../../routes/routes';
import { setPage } from '../../redux/slices/globalSlice';
import { STATE_PENDING_VERIFICATION } from '../../redux/slices/authSlice';
import { useLocation } from 'react-router-dom';
import MobileHeader from '../Pages/Header/MobileHeader';
import { Desktop, Mobile } from '../Breakpoints';
import { useCookies } from 'react-cookie';
const TrialBanner = lazy(() => import('../TrialBanner/TrialBanner'));
const ExpirationModal = lazy(() => import('../ExpirationModal/ExpirationModal'));
const FreeTrialModal = lazy(() => import('../Modals/FreeTrialModal/FreeTrialModal'));
const SelfOnboardingSuspendedModal = lazy(
  () => import('../Modals/SelfOnboardingSuspendedModal/SelfOnboardingSuspendedModal'),
);

const DashboardLayout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const userVerificationInfo = useSelector((state) => state.userReducer.verificationInfo);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const suspendedModalVisible = useSelector((state) => state.globalReducer.suspendedModalVisible);
  const [modalExp, setModalExp] = useState(false);
  const [cookies, setCookie] = useCookies(['vaultodyFreeTrialBanner']);
  const [openFreeTrialMessageModal, setOpenFreeTrialMessageModal] = useState(
    !cookies?.vaultodyFreeTrialBanner || false,
  );

  const onIdle = () => {
    if (!window.location.href.includes('localhost')) {
      setModalExp(true);
      localStorage.clear();
    }
  };

  useIdleTimer({
    onIdle: onIdle,
    timeout: 600_000,
    throttle: 500,
  });

  const logOutFuntion = () => {
    window.location.replace(ROUTE_HOME);
  };

  const renderSubscriptionElement = () => {
    if (suspendedModalVisible) {
      return (
        <Suspense fallback={null}>
          <SelfOnboardingSuspendedModal />
        </Suspense>
      );
    }
    if (
      (userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL ||
        userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL) &&
      myProfile?.state?.toLowerCase() === STATE_PENDING_VERIFICATION &&
      trialBannerVisible
    ) {
      return (
        <div className='trial-banner-wrapper'>
          <Suspense fallback={null}>
            <TrialBanner />
          </Suspense>
        </div>
      );
    }
    return null;
  };

  // if (window.location.pathname === ROUTE_PRICING_TABLE) {
  //   return children;
  // }

  return (
    Object.values(lng).length !== 0 && (
      <div className={`dashboard ${localStorage.getItem('inactive') ? 'inactive-request' : ''}`}>
        <div className='main'>
          {modalExp && (
            <Suspense fallback={null}>
              <ExpirationModal closeFunction={logOutFuntion} />
            </Suspense>
          )}
          {openFreeTrialMessageModal &&
            (userVerificationInfo?.state?.toUpperCase() === clientStatuses.PROVISIONAL ||
              userVerificationInfo?.state?.toUpperCase() === clientStatuses.TRIAL) &&
            myProfile?.state?.toLowerCase() === STATE_PENDING_VERIFICATION && (
              <Suspense fallback={null}>
                <div className='free-trial-message-wrapper'>
                  <FreeTrialModal
                    onClose={() => {
                      const oneYearFromNow = new Date();
                      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
                      setCookie('vaultodyFreeTrialBanner', true, { expires: oneYearFromNow });
                      setOpenFreeTrialMessageModal(false);
                    }}
                  />
                </div>
              </Suspense>
            )}
          <Desktop>
            <Header />
          </Desktop>
          <Mobile>
            <MobileHeader />
          </Mobile>
          <div className='main-dashboard'>
            <Desktop>
              <DashboardMenu
                hadleSetPage={(pageName) => dispatch(setPage(pageName))}
                link={location.pathname}
              />
            </Desktop>
            <div className='content-page'>
              {renderSubscriptionElement()}
              <TitleBox name={location.pathname} />
              <div className='content'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DashboardLayout;
