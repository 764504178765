import './GuestLayout.css';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import logo from '../assets/images/logo.svg';
import backgroundReg from '../assets/images/bg-guest.png';
import { Desktop } from './Breakpoints';

const GuestLayout = (props) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  return Object.values(lng).length !== 0 ? (
    <div className='layout-guest'>
      <div className='guest-layout'>
        <div className='logo'>
          <img src={logo} />
        </div>
        {props.children}
      </div>
      <Desktop>
        <div
          className='layout-guest-sidebanner'
          style={{
            backgroundImage: `url(${backgroundReg})`,
          }}
        >
          <div>
            <p className='layout-guest-sidebanner-title'>
              Custody Technology Solution for Managing Digital Assets
            </p>
            <p className='layout-guest-sidebanner-subtitle'>
              Vaultody is a cutting-edge technology solution enabling companies and institutions to securely
              operate with their digital assets.
            </p>
          </div>
        </div>
      </Desktop>
    </div>
  ) : (
    <div className='layout-guest-loading'>
      <ReactLoading type='spin' color='##020D1C' />
    </div>
  );
};

export default GuestLayout;
