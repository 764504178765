import { T } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../../redux/slices/globalSlice';
import { useEffect } from 'react';
import WalletTableRow from '../../Pages/Wallets/WalletTableRow';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '../../Skeleton/Skeleton';

const WalletTable = ({ handleSelectWalletId, data = [], loading }) => {
  const dispatch = useDispatch();
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const isLargeDesktopScreen = useMediaQuery({ minWidth: 1460 });

  const renderRow = ({ index, style }) => {
    return (
      <div style={style}>
        <WalletTableRow
          key={`wallet-row-${index}`}
          wallet={data?.[index]}
          handleSelectWalletId={handleSelectWalletId}
        />
      </div>
    );
  };

  useEffect(() => {
    dispatch(setPage(''));
  }, []);

  return (
    <div className={`table wallets-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      <div className='table-content'>
        <div className='table-row caption wallet-table-header'>
          <div className='vault-table-data name'>
            <T>app.name</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.available.balance</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.total.balance</T>
          </div>
          <div className='vault-table-data  sum'>
            <T>app.aml.blocked</T>
          </div>
          <div className='vault-table-data sum'>
            <T>app.allocated</T>
          </div>
          <div className='vault-table-data settings' />
        </div>
        {loading && (
          <div className='loading-full-height'>
            <Skeleton
              rowHeight={isMobile ? 351 : isLargeDesktopScreen ? 117 : 167}
              padding={isMobile ? 20 : 50}
            />
          </div>
        )}
        {!loading && data?.length > 0 && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                className='wallet-window-list'
                height={height}
                itemCount={data?.length}
                itemSize={isMobile ? 351 : isLargeDesktopScreen ? 117 : 167}
                width={width}
              >
                {renderRow}
              </List>
            )}
          </AutoSizer>
        )}
        {!loading && data.length === 0 && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.wallets</T>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WalletTable;
