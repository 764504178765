export const copy = (address) => {
  navigator.clipboard.writeText(address);
};

export const smallAddress = (address, number) => {
  if (!address) return '';
  return `${address.slice(0, number)}...${address.slice(address.length - number, address.length)}`;
};

export const findFittingElementsIntoContainer = (strings, containerWidth, element) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = getCanvasFont(element);

  let accumulatedString = '';
  const longestFittingElements = [];

  for (let i = 0; i < strings.length; i++) {
    const currentString = strings[i];
    if (accumulatedString !== '') {
      accumulatedString += ', ';
    }
    const currentStringWidth = context.measureText(accumulatedString + currentString).width;

    if (currentStringWidth > containerWidth) {
      break;
    }
    accumulatedString += currentString;
    longestFittingElements.push(currentString);
  }

  return longestFittingElements;
};

const getCssStyle = (element, prop) => {
  return window.getComputedStyle(element, null).getPropertyValue(prop);
};

const getCanvasFont = (el = document.body) => {
  const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
  const fontSize = getCssStyle(el, 'font-size') || '16px';
  const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

  return `${fontWeight} ${fontSize} ${fontFamily}`;
};

// Measures the text width and returns the items to display in the filter text field
export const getSelectedItemsToDisplay = (containerWidth, textElement, items, dataNameKey, lastEvent) => {
  const elementsArray = findFittingElementsIntoContainer(
    items?.map((item) => item?.[dataNameKey || 'name']),
    containerWidth,
    textElement,
  );
  return items?.slice(0, elementsArray?.length);
};

// Check if the text in the text field is overflowing and show the indicator. Example - "+1"
export const handleTextIndicator = (
  items,
  dataNameKey,
  textFieldRef,
  indicatorData,
  setIndicatorData,
  filterIndicatorWidth,
  selectedFieldsSpanRef,
  lastEvent,
) => {
  if (indicatorData?.show && items?.length <= indicatorData?.visibleSelectedItems?.length) {
    setIndicatorData({ show: false, visibleSelectedItems: [] });
    return;
  }
  if (items?.length) {
    const computedStyle = getComputedStyle(textFieldRef);
    // width with padding
    let elementWidth = textFieldRef?.clientWidth;
    // width without padding
    elementWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    const isTextOverflowing =
      selectedFieldsSpanRef?.current?.offsetWidth &&
      selectedFieldsSpanRef?.current?.offsetWidth >= elementWidth - filterIndicatorWidth;
    if (!isTextOverflowing && !indicatorData?.show) return;
    const visibleSelectedItems = getSelectedItemsToDisplay(
      elementWidth - filterIndicatorWidth,
      selectedFieldsSpanRef?.current,
      items,
      dataNameKey,
      lastEvent,
    );
    if (visibleSelectedItems?.length === 0) return;
    setIndicatorData({
      visibleSelectedItems: visibleSelectedItems,
      show: true,
    });
  }
};
