import { T, t } from '../../T';
import Button from '../../Button/Button';
import { useSelector } from 'react-redux';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import AutomationRulesTable from './AutomationRulesTable/AutomationRulesTable';
import {
  deleteWalletAutomationRuleApi,
  getWalletAutomationRuleApi,
  getWalletAutomationRuleSearchApi,
  getWalletAutomationRulesApi,
} from '../../../api/endpoints';
import Alert from '../../Alert/Alert';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-icon.svg';
import ActionModal from '../../Modals/ActionModal';
import Textfield from '../../Textfield/Textfield';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-icon.svg';
import useDebounce from '../../../helpers/hooks/useDebounce';
const VTooltip = lazy(() => import('../../VTooltip/VTooltip'));
const BasicModal = lazy(() => import('../../Modals/BasicModal/BasicModal'));
const CreateVaultAutomationRule = lazy(
  () => import('../../Modals/CreateVaultAutomationRule/CreateVaultAutomationRule'),
);

const WalletAutomationRules = ({ wallet, backup }) => {
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [automationRules, setAutomationRules] = useState();
  const [openAutomationRuleModal, setOpenAutomationRuleModal] = useState({ open: false });
  const [openDeleteVaultAutomationRule, setOpenDeleteVaultAutomationRule] = useState({ open: false });
  const [openDeleteSuccessModal, setOpenDeleteSuccessModal] = useState(false);
  const [openCreateSuccessModal, setOpenCreateSuccessModal] = useState(false);
  const automationCreatePermission = myProfile.premmisions?.includes('AUTOMATION_RULES_CREATE');

  const getAutomationRules = async () => {
    setLoading(true);
    getWalletAutomationRulesApi(wallet?.id)
      .then((res) => {
        setAutomationRules(res?.data?.items);
      })
      .catch(() => setAutomationRules([]))
      .finally(() => setLoading(false));
  };

  const handleDeleteAutomationRule = (ruleId) =>
    deleteWalletAutomationRuleApi(ruleId).then(() => {
      setOpenDeleteVaultAutomationRule({ open: false });
      setOpenDeleteSuccessModal(true);
    });

  const getAutomationRulesBySearching = async () => {
    setLoading(true);
    return getWalletAutomationRuleSearchApi(wallet?.id, {
      params: {
        item: searchText,
      },
    })
      .then((res) => setAutomationRules(res.data?.items))
      .finally(() => setLoading(false));
  };

  useDebounce(
    () => {
      if (searchText?.length > 2) {
        getAutomationRulesBySearching();
      } else {
        getAutomationRules();
      }
    },
    500,
    [searchText],
  );

  useEffect(() => {
    if (typeUser) {
      setSearchText('');
    }
  }, [typeUser]);

  useEffect(() => {
    getAutomationRules();
  }, [myProfile.premmisions]);

  useEffect(() => {
    if (typeof messageSocket === 'object' && typeUser) {
      if (
        messageSocket?.data?.event === 'automation_rule_creation_approved' ||
        messageSocket?.data?.event === 'automation_rule_creation_rejected' ||
        messageSocket?.data?.event === 'automation_rule_update_approved' ||
        messageSocket?.data?.event === 'automation_rule_delete_approved'
      ) {
        setOpenCreateSuccessModal(false);
        setOpenDeleteSuccessModal(false);
        getAutomationRules();
      }
    }
  }, [messageSocket, typeUser]);

  return (
    <>
      {openAutomationRuleModal?.open && (
        <Suspense fallback={null}>
          <CreateVaultAutomationRule
            wallet={wallet}
            successCallback={() => {
              setOpenAutomationRuleModal({ open: false });
              setOpenCreateSuccessModal(true);
              getAutomationRules();
            }}
            editData={openAutomationRuleModal?.editData}
            toggleFunction={() => setOpenAutomationRuleModal({ open: false })}
          />
        </Suspense>
      )}
      {openCreateSuccessModal && (
        <ActionModal toggleFunction={setOpenCreateSuccessModal}>
          <T>app.trans.req.descr</T>
        </ActionModal>
      )}
      {openDeleteSuccessModal && (
        <ActionModal toggleFunction={setOpenDeleteSuccessModal}>
          <T>app.trans.req.descr</T>
        </ActionModal>
      )}
      {openDeleteVaultAutomationRule?.open && (
        <Suspense fallback={null}>
          <BasicModal
            submitButtonColor='#DC3241'
            submitButtonTextColor='#FFFFFF'
            submitButtonText={t(lng, 'app.yes.delete.automation')}
            closeCallback={() => setOpenDeleteVaultAutomationRule({ open: false })}
            submitCallback={() => handleDeleteAutomationRule(openDeleteVaultAutomationRule?.rule?.id)}
            title={t(lng, 'app.are.you.sure.remove.automation.rule')}
          >
            <div className='remove-automation-key-info-wrapper'>
              <span className='remove-api-key-info-text'>
                <T>app.automation.rule.name</T>
              </span>
              <span className='remove-api-key-info-sub-text'>
                {openDeleteVaultAutomationRule?.rule?.name}
              </span>
            </div>
            <Alert variant='error' text={t(lng, 'app.deleting.automation.consequences')} />
          </BasicModal>
        </Suspense>
      )}
      <div className='automation-rules-header-table'>
        <Textfield
          type='text'
          fullWidth
          icon={<SearchIcon />}
          placeholder={t(lng, 'app.search.automation.rules')} // 'Search by address name or address'
          size='lg'
          value={searchText}
          onChange={(e) => setSearchText(e.currentTarget.value)}
        />
        <div className='button-holder'>
          <Button
            size='md'
            icon={<PlusIcon style={{ margin: '0 5px 3px 0' }} />}
            onClick={() => setOpenAutomationRuleModal({ open: true })}
            disabled={!automationCreatePermission || !backup}
            data-tooltip-id='automation-rule-create'
            data-tooltip-content={t(lng, 'app.role.limitation')}
          >
            {(!automationCreatePermission || !backup) && (
              <Suspense fallback={null}>
                <VTooltip id='automation-rule-create' />
              </Suspense>
            )}
            <T>app.create.new.automation.rule</T>
          </Button>
        </div>
      </div>
      <AutomationRulesTable
        loading={loading}
        data={automationRules}
        handleDeleteRule={(rule) => setOpenDeleteVaultAutomationRule({ open: true, rule: rule })}
        handleEditRule={(rule) =>
          setOpenAutomationRuleModal(() => {
            getWalletAutomationRuleApi(rule?.id).then((res) =>
              setOpenAutomationRuleModal({ open: true, editData: res?.data?.item }),
            );
          })
        }
      />
    </>
  );
};

export default WalletAutomationRules;
