import React, { useEffect, useState, useMemo, Suspense, lazy } from 'react';
import { blockchainScannersIcons } from '../../../helpers/protocolIcons/protocolIcons';
import { getBlockchainScannerLink } from '../../../helpers/blockchain/blockchain';
import CopyButton from '../../CopyButton/CopyButton';
import './AddressAccordionTitle.css';
import { useSelector } from 'react-redux';
import Button from '../../Button/Button';
import { T, t } from '../../T';
import activeIcon from '../../../assets/icons/statuses/status-signed.svg';
import pendingIcon from '../../../assets/icons/statuses/status-pending.svg';
import failedIcon from '../../../assets/icons/statuses/status-failed.svg';
import CryptoIcon from '../../CryptoIcon/CryptoIcon';
import { currency } from '../../../helpers/currency';
import editIcon from '../../../assets/icons/pencil-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkmark.svg';
import Textfield from '../../Textfield/Textfield';
import { editAddressApi } from '../../../api/endpoints';
import { vaultTypes } from '../../../helpers/constants';
const VTooltip = lazy(() => import('../../VTooltip/VTooltip'));

const AddressAccordionTitle = ({ address, vault, linkAddressCallback }) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const [isEditName, setIsEditName] = useState(false);
  const [addressName, setAddressName] = useState(address?.name || '');
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [vaultodyDateFunc, setVaultodyDateFunc] = useState();
  const isSmartVault =
    vault?.vaultType?.toLowerCase() === vaultTypes.SMART ||
    vault?.vaultType?.toLowerCase() === vaultTypes.AUTOMATION;
  const addressNameUpdatePermission = myProfile.premmisions?.includes('ADDRESS_NAME_UPDATE');

  const handleEditName = () => {
    editAddressApi(
      {
        item: {
          name: addressName,
        },
      },
      address?.id,
    )
      .then(() => setHasBeenEdited(true))
      .catch(() => setAddressName(address?.name || ''))
      .finally(() => setIsEditName(false));
  };

  const renderLinkStatusIcon = useMemo(() => {
    switch (address?.linkStatus?.toLowerCase()) {
      case 'linked':
        return { icon: activeIcon, text: t(lng, 'app.linked') };
      case 'in-progress':
        return { icon: pendingIcon, text: t(lng, 'app.linking') };
      case 'not-linked':
        return { icon: failedIcon, text: t(lng, 'app.not.linked') };
      default:
        return { icon: null, text: '' };
    }
  }, []);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'Enter' && isEditName) {
        handleEditName();
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isEditName, addressName]);

  useEffect(() => {
    const loadVaultodyDate = async () => {
      const module = await import('../../../helpers/date');
      setVaultodyDateFunc(() => module.vaultodyDate);
    };
    loadVaultodyDate();
  }, []);

  return (
    <div
      className='address-accordion-main-wrapper'
      style={{ display: 'flex', width: '100%', alignItems: 'center' }}
    >
      {isEditName ? (
        <div
          className={`addresses-table-data name${isSmartVault ? '-link' : ''} address-name-edit`}
          onClick={(e) => e?.stopPropagation()}
        >
          <span className='mobile-cell-title'>
            <T>app.address.name</T>
          </span>
          <div className='address-title-table-data-flex'>
            <Textfield
              trim
              type='text'
              name='addressName'
              size='sm'
              error={
                addressName?.length > 100 || addressName?.length < 3
                  ? t(lng, 'app.address.name.length.error')
                  : undefined
              }
              showValidationEndIcons
              fullWidth
              placeholder={t(lng, 'app.address.name.placeholder')}
              value={addressName}
              errorTooltipProps={{ positionStrategy: 'fixed' }}
              onChange={(e) => setAddressName(e?.target?.value)}
            />
            <CheckIcon
              className={`addresses-table-data-check-icon ${
                addressName?.length === 0 || addressName?.length > 100 || addressName?.length < 3
                  ? 'disabled'
                  : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleEditName();
              }}
            />
            <div
              className='addresses-table-data-close-icon icon-close'
              onClick={(e) => {
                e.stopPropagation();
                setIsEditName(false);
              }}
            />
          </div>
        </div>
      ) : (
        <div
          className={`addresses-table-data name${isSmartVault ? '-link' : ''}`}
          data-tooltip-id={`address-name-${address}`}
          data-tooltip-content={hasBeenEdited ? addressName : address.name}
        >
          <span className='mobile-cell-title'>
            <T>app.address.name</T>
          </span>
          <div className='address-title-table-data-flex'>
            <Suspense fallback={null}>
              <VTooltip id={`address-name-${address}`} place='top-start' />
            </Suspense>
            <span className='ellipsis'>
              {address.type === 'change' && !address?.name && !hasBeenEdited
                ? 'Change address'
                : hasBeenEdited
                  ? addressName
                  : address.name}
            </span>
            <img
              data-tooltip-id='disabled-wallet-update'
              data-tooltip-content={t(lng, 'app.role.limitation')}
              src={editIcon}
              className={`addresses-table-data-edit-icon ${addressNameUpdatePermission ? '' : 'disabled'}`}
              onClick={(e) => {
                if (addressNameUpdatePermission) {
                  e.stopPropagation();
                  setIsEditName(true);
                }
              }}
            />
            {!addressNameUpdatePermission && (
              <Suspense fallback={null}>
                <VTooltip id='disabled-wallet-update' />
              </Suspense>
            )}
          </div>
        </div>
      )}
      <div className={`addresses-table-data blockchain${isSmartVault ? '-link' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.blockchain</T>
        </span>
        <div className='address-title-table-data-flex-gap'>
          <CryptoIcon
            className='token-image'
            symbol={
              protocols?.find(
                (protocol) => protocol?.blockchain?.toLowerCase() === address?.blockchain?.toLowerCase(),
              )?.currency
            }
          />
          <span>{address.blockchain.replaceAll('-', ' ')}</span>
        </div>
      </div>
      <div className={`addresses-table-data trx-address${isSmartVault ? '-link' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.address</T>
        </span>
        <div className='address-title-table-data-flex-gap'>
          <div className='wallets-address-text-addresses'>
            {address.address.substring(0, 5) +
              '...' +
              address.address.substring(address.address.length - 5, address.address.length)}{' '}
          </div>
          <CopyButton element={address.address} />
        </div>
      </div>
      <div className={`addresses-table-data created-at${isSmartVault ? '-link' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.created</T>
        </span>
        <div>
          <span className='date'>
            {address?.type === 'station'
              ? currency(address.totalAmountInUsd, 'full')
              : vaultodyDateFunc
                ? vaultodyDateFunc(address?.createdAt, 'full')
                : null}
          </span>
        </div>
      </div>
      {isSmartVault && (
        <div className='addresses-table-data link-status'>
          <span className='mobile-cell-title'>
            <T>app.link.status</T>
          </span>
          <div className='address-title-table-data-flex-gap'>
            <img className='link-status-value' src={renderLinkStatusIcon?.icon} alt='' />
            <span className='link-status-text'>{renderLinkStatusIcon?.text}</span>
          </div>
        </div>
      )}
      <div className={`addresses-table-data settings${isSmartVault ? '-link' : ''}`}>
        {vault?.vaultType?.toLowerCase() === vaultTypes.SMART &&
          address?.type === 'smart-deposit' &&
          address?.linkStatus?.toLowerCase() === 'not-linked' && (
            <Button size='sm' variant='neutral' onClick={(e) => linkAddressCallback(e, address)}>
              <T>app.link</T>
            </Button>
          )}
        <Button
          size='sm'
          variant='neutral'
          onClick={() => {
            window.open(
              getBlockchainScannerLink(
                address.blockchain.toLowerCase(),
                address.address,
                address.network.toLowerCase(),
                true,
              ),
              '_blank',
            );
          }}
        >
          <img
            className='blochain-scanner-logo'
            src={blockchainScannersIcons(address.blockchain.toLowerCase())}
          />
        </Button>
      </div>
    </div>
  );
};

export default AddressAccordionTitle;
