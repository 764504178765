import React, { Suspense, lazy, useRef } from 'react';
import { useSelector } from 'react-redux';
import Blockchain from '../../../Blockchain/Blockchain';
import deleteIcon from '../../../../assets/icons/delete-icon.svg';
import editIcon from '../../../../assets/icons/pencil-icon.svg';
import { T, t } from '../../../T';
import { smallAddress } from '../../../../helpers/strings';
import CopyButton from '../../../CopyButton/CopyButton';
import ActionMenu from '../../../ActionMenu/ActionMenu';
const VTooltip = lazy(() => import('../../../VTooltip/VTooltip'));

const AutomationRuleRow = ({ rule, status, handleEdit, handleDelete }) => {
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const refW = useRef('gl-settings');

  return (
    <div className='table-row data' ref={refW}>
      <div
        className='name'
        data-tooltip-id={`automation-rule-name-${rule?.id}`}
        data-tooltip-content={rule.name}
      >
        <span className='mobile-cell-title'>
          <T>app.name</T>
        </span>

        <div>
          <Suspense fallback={null}>
            <VTooltip id={`automation-rule-name-${rule?.id}`} />
          </Suspense>
          {rule.name}
        </div>
      </div>
      <div className='asset'>
        <span className='mobile-cell-title'>
          <T>app.assets</T>
        </span>
        <div className='create-vault-automation-rule-modal-summary-blockchain'>
          <Blockchain
            assetType={rule?.asset.type?.toUpperCase()}
            blockchain={protocols
              ?.find((protocol) => protocol?.blockchain?.toLowerCase() === rule?.blockchain?.toLowerCase())
              ?.currency?.toLowerCase()}
            symbol={rule?.asset.symbol}
          />
          <span>{` ${rule?.asset.symbol}`}</span>
        </div>
      </div>
      <div className='asset'>
        <span className='mobile-cell-title'>
          <T>app.trigger</T>
        </span>
        <div className='create-vault-automation-rule-modal-summary-blockchain'>
          <Blockchain
            assetType={rule?.asset.type?.toUpperCase()}
            blockchain={protocols
              ?.find((protocol) => protocol?.blockchain?.toLowerCase() === rule?.blockchain?.toLowerCase())
              ?.currency?.toLowerCase()}
            symbol={rule?.asset.symbol}
          />
          <span>{` ${rule?.amountRange?.from} - `}</span>
          <span style={{ fontSize: '20px', marginTop: '3px' }}>&infin;</span>
        </div>
      </div>
      <div
        className='fee-priority'
        data-tooltip-id='automation-destination-address'
        data-tooltip-content={rule?.action?.destination}
      >
        <span className='mobile-cell-title'>
          <T>app.destination</T>
        </span>
        <div className='wallets-specific-container-flex-gap'>
          <Suspense fallback={null}>
            <VTooltip id='automation-destination-address' />
          </Suspense>
          {smallAddress(rule?.action?.destination, 6)}
          <CopyButton element={rule?.action?.destination} />
        </div>
      </div>
      <div className='status'>
        <span className='mobile-cell-title'>
          <T>app.status</T>
        </span>
        <div className='wallets-specific-container-flex-gap'>
          <img src={status?.icon} className='status-image' />
          <div className='status-name capitalize'>{status?.name}</div>
        </div>
      </div>
      <div className='settings'>
        <span className='mobile-cell-title'>
          <T>app.settings</T>
        </span>
        {status?.value?.toLowerCase() === 'approved' && (
          <ActionMenu
            data={rule}
            items={[
              {
                name: t(lng, 'app.edit.automation.rule'),
                icon: editIcon,
                permission: myProfile.premmisions?.includes('AUTOMATION_RULES_UPDATE'),
                callback: (rule) => handleEdit(rule),
              },
              {
                name: t(lng, 'app.delete.automation.rule'),
                icon: deleteIcon,
                permission: myProfile.premmisions?.includes('AUTOMATION_RULES_DELETE'),
                callback: (rule) => handleDelete(rule),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default AutomationRuleRow;
