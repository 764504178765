import { T } from '../../components/T';
import approvallevel1Icon from '../../assets/icons/statuses/status-approvallevel1.svg';
import approvallevel2Icon from '../../assets/icons/statuses/status-approvallevel2.svg';
import broadcastedIcon from '../../assets/icons/statuses/status-broadcasted.svg';
import failedIcon from '../../assets/icons/statuses/status-failed.svg';
import initiatedIcon from '../../assets/icons/statuses/status-initiated.svg';
import pendingIcon from '../../assets/icons/statuses/status-pending.svg';
import rejectedIcon from '../../assets/icons/statuses/status-rejected.svg';
import signedIcon from '../../assets/icons/statuses/status-signed.svg';
import notStartedIcon from '../../assets/icons/statuses/status-not-started.svg';
import mailedIcon from '../../assets/icons/statuses/status-mailed.svg';
import viewedIcon from '../../assets/icons/statuses/status-viewed.svg';
import documentIcon from '../../assets/icons/document-icon2.svg';
import receiveIcon from '../../assets/icons/receive-icon.svg';
import sendIcon from '../../assets/icons/send-icon.svg';
import internalIncomingIcon from '../../assets/icons/internal-incoming-icon.svg';
import internalOutgoingIcon from '../../assets/icons/Internal-outgoing-icon.svg';
// import infoIcon from "../../assets/icons/statuses/status-info.svg";

export const contactsStatuses = [
  {
    value: 'PENDING-APPROVAL',
    name: <T>app.contacts.pending.approval</T>,
    icon: pendingIcon,
  },
  {
    value: 'APPROVED',
    name: <T>app.contacts.approved</T>,
    icon: signedIcon,
  },
  {
    value: 'DELETED',
    name: <T>app.contacts.deleted</T>,
    icon: failedIcon,
  },
  {
    value: 'REJECTED',
    name: <T>app.contacts.rejected</T>,
    icon: rejectedIcon,
  },
];

export const automationRuleStatuses = [
  {
    value: 'PENDING-APPROVAL',
    name: <T>app.contacts.pending.approval</T>,
    icon: pendingIcon,
  },
  {
    value: 'APPROVED',
    name: <T>app.contacts.approved</T>,
    icon: signedIcon,
  },
  {
    value: 'DELETED',
    name: <T>app.contacts.deleted</T>,
    icon: failedIcon,
  },
  {
    value: 'REJECTED',
    name: <T>app.contacts.rejected</T>,
    icon: rejectedIcon,
  },
];

export const statuses = {
  CREATED: 'created',
  AWAIT_APPROVAL: 'await_approval',
  PENDING: 'pending',
  PREPARED: 'prepared',
  SIGNED: 'signed',
  BROADCASTED: 'broadcasted',
  SUCCESS: 'success',
  FAILED: 'failed',
  REJECTED: 'rejected',
  MINED: 'mined',
  MINED_WITH_ERRORS: 'mined_with_errors',
  AWAIT_APPROVAL_L2: 'await_approval_l2',
};

export const transactionRequestStatuses = [
  {
    value: 'AWAIT_APPROVAL',
    name: <T>app.filters.approval</T>,
    icon: approvallevel1Icon,
  },
  {
    value: 'PENDING',
    name: <T>app.filters.pending</T>,
    icon: pendingIcon,
  },
  {
    value: 'BROADCASTED',
    name: <T>app.filters.broadcasted</T>,
    icon: broadcastedIcon,
  },
  {
    value: 'FAILED',
    name: <T>app.filters.failed</T>,
    icon: failedIcon,
  },
  {
    value: 'REJECTED',
    name: <T>app.filters.rejected</T>,
    icon: rejectedIcon,
  },
  {
    value: 'AWAIT_APPROVAL_L2',
    name: <T>app.filters.approval2</T>,
    icon: approvallevel2Icon,
  },
];

export const transactionHistoryStatuses = [
  {
    value: 'MINED',
    name: <T>app.filters.mined</T>,
    icon: signedIcon,
  },
  {
    value: 'MINED_WITH_ERRORS',
    name: <T>app.filters.minederrors</T>,
    icon: failedIcon,
  },
];

export const allTransactionStatuses = [
  {
    value: 'PENDING_APPROVAL',
    name: <T>app.filters.pending</T>,
    icon: pendingIcon,
  },
  {
    value: 'CREATED',
    name: '',
    icon: signedIcon,
  },
  {
    value: 'PREPARED',
    name: '',
    icon: initiatedIcon,
  },
  {
    value: 'SIGNED',
    name: '',
    icon: signedIcon,
  },
  {
    value: 'SUCCESS',
    name: '',
    icon: signedIcon,
  },
  ...transactionRequestStatuses,
  ...transactionHistoryStatuses,
];

export const signedContractStatuses = [
  {
    value: 'NONE',
    name: <T>app.status.notcreated</T>,
    icon: notStartedIcon,
    color: '#f7931a',
  },
  {
    value: 'MAILED',
    name: <T>app.status.mailed</T>,
    icon: mailedIcon,
    color: '#0044f3',
  },
  {
    value: 'VIEWED',
    name: <T>app.status.viewed</T>,
    icon: viewedIcon,
    color: '#e24bb1',
  },
  {
    value: 'SIGNED',
    name: <T>app.status.signed</T>,
    icon: signedIcon,
    color: '#1FA063',
  },
];

export const sumSubStatuses = [
  {
    value: 'NONE',
    name: <T>app.status.notcreated</T>,
    icon: notStartedIcon,
    color: '#f7931a',
  },
  {
    value: 'INITIATED',
    name: <T>app.status.initiated</T>,
    icon: initiatedIcon,
    color: '#0044f3',
  },
  {
    value: 'PENDING',
    name: <T>app.filters.pending</T>,
    icon: pendingIcon,
    color: '#f7931a',
  },
  {
    value: 'REJECTED',
    name: <T>app.status.rejected</T>,
    icon: rejectedIcon,
    color: '#d10000',
  },
  {
    value: 'FAILED',
    name: <T>app.status.failed</T>,
    icon: failedIcon,
    color: '#d10000',
  },
  {
    value: 'COMPLETED',
    name: <T>app.status.completed</T>,
    icon: signedIcon,
    color: '#1FA063',
  },
];

export const paymentStatuses = [
  {
    value: 'NOT_RECEIVED',
    name: <T>app.status.notreceived</T>,
    icon: pendingIcon,
    color: '#d10000',
  },
  {
    value: 'RECEIVED',
    name: <T>app.status.received</T>,
    icon: signedIcon,
    color: '#1FA063',
  },
];

export const transactionDraftsStatuses = [
  {
    value: 'created',
    name: <T>app.draft</T>,
    icon: documentIcon,
  },
  {
    value: 'converted',
    name: <T>app.status.converted</T>,
    icon: signedIcon,
    color: '#1FA063',
  },
  {
    value: 'deleted',
    name: <T>app.contacts.deleted</T>,
    icon: failedIcon,
  },
  {
    value: 'failed',
    name: <T>app.filters.failed</T>,
    icon: failedIcon,
  },
];

export const transactionDirections = [
  {
    value: 'vault-internal',
    name: <T>app.vault-internal</T>,
    icon: internalOutgoingIcon,
  },
  {
    value: 'client-internal',
    name: <T>app.client-internal</T>,
    icon: internalIncomingIcon,
  },
  {
    value: 'incoming',
    name: <T>app.incoming</T>,
    icon: receiveIcon,
  },
  {
    value: 'outgoing',
    name: <T>app.outgoing</T>,
    icon: sendIcon,
  },
];

export const exportTrxStatuses = [
  {
    value: 'DONE',
    name: <T>app.status.done</T>,
    icon: signedIcon,
    color: '#1FA063',
  },
  {
    value: 'INITIATED',
    name: <T>app.status.initiated</T>,
    icon: pendingIcon,
    color: '#c1c1c1',
  },
];
