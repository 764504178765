import './Error.css';
import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { T } from '../../components/T';
const LazyLottie = lazy(() => import('../../components/LazyLottie/LazyLottie'));

const Error = () => {
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  return (
    <div className={`error-page-table-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      <h2 className='error-page-label'>
        <T>app.not.found</T>
      </h2>
      <Suspense fallback={null}>
        <LazyLottie
          animationName={'error-404.json'}
          options={{
            loop: true,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={200}
          width={600}
        />
      </Suspense>
    </div>
  );
};
export default Error;
