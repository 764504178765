import { Suspense, lazy } from 'react';
import './LandscapeOrientationBlocker.css';
const LazyLottie = lazy(() => import('../../components/LazyLottie/LazyLottie'));

const LandscapeOrientationBlocker = () => {
  return (
    <div className='landscape-warning-overlay'>
      <Suspense fallback={null}>
        <LazyLottie
          animationName={'portrait-mode.json'}
          style={{ transform: 'rotate(-90deg)', width: '50vw', height: '100vh', padding: 0, margin: 0 }}
          options={{
            loop: true,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </Suspense>
    </div>
  );
};

export default LandscapeOrientationBlocker;
