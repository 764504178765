import { useEffect, useState, useMemo, Suspense, lazy } from 'react';
import '../TransactionRequests/TransactionRequests.css';
import { T } from '../../components/T';
import { getTrxDetailsApi, getTrxHistoryApi, getWalletsApi } from '../../api/endpoints';
import { cloneDeep } from 'lodash';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { unique } from '../../helpers/array';
import TransactionRequestsТable from '../../components/Tables/TransactionRequestsТable';
import { useOutletContext } from 'react-router-dom';
const MobileFiltersBar = lazy(() => import('./components/MobileFiltersBar'));
const DesktopFiltersBar = lazy(() => import('./components/DesktopFiltersBar'));
const DetailsTransactionRequest = lazy(() => import('../../components/Modals/DetailsTransactionRequest'));
const TransactionFilterMenu = lazy(
  () => import('../../components/TransactionFilterMenu/TransactionFilterMenu'),
);
// import ExportHistory from './components/ExportHistory';

const TransactionsHistory = ({ wallet }) => {
  const [activeTab, isTabViewPermissionMissing] = useOutletContext();
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [trxFilters, setTrxFilters] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [popperElement, setPopperElement] = useState(null);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [openDetailsTrasactionModal, setOpenDetailsTrasactionModal] = useState(false);
  // const isExportHistory = activeTab === 'export-history';
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: true,
        },
      },
    ],
  });

  const handleFiltersApiParams = useMemo(() => {
    const trxFiltersObj = trxFilters.reduce((accumulator, item) => {
      if (item?.filterName === 'blockchains') {
        if (item?.value?.length === unique(protocols, 'blockchain')?.length) {
          return { ...accumulator };
        }
        return {
          ...accumulator,
          [item?.filterName]: item?.value?.map((protocol) => protocol?.blockchain),
        };
      }
      if (item?.filterName === 'direction') {
        return { ...accumulator, [item?.filterName]: item?.value?.[0]?.value };
      }
      if (item?.filterName === 'dateRange') {
        if (item?.value?.[0] && item?.value?.[1]) {
          return {
            ...accumulator,
            fromDate: Math.floor(new Date(item?.value?.[0]).getTime() / 1000),
            toDate: Math.floor(new Date(item?.value?.[1]).getTime() / 1000),
          };
        } else if (item?.value?.[0]) {
          return {
            ...accumulator,
            fromDate: Math.floor(new Date(item?.value?.[0]).getTime() / 1000),
          };
        }
        return { ...accumulator };
      }
      if (item?.filterName === 'wallets') {
        if (item?.value?.length === wallets?.length) {
          return { ...accumulator };
        }
        return { ...accumulator, [item?.filterName]: item?.value?.map((wallet) => wallet?.id) };
      }
      return { ...accumulator, [item?.filterName]: item?.value };
    }, {});
    if (wallet?.id) {
      return { ...trxFiltersObj, wallets: [wallet?.id] };
    }
    return trxFiltersObj;
  }, [trxFilters]);

  const getDetailsData = (transaction) => {
    const even = { even: false };
    if (
      transaction.transaction.direction === 'vault-internal' ||
      transaction.transaction.direction === 'client-internal'
    ) {
      even.even = true;
    }
    getTrxDetailsApi(transaction.transaction.id).then((res) => {
      setDetailsData({
        ...res.data.item,
        transaction: {
          ...res.data.item?.transaction,
          direction: transaction.transaction.direction,
        },
        ...even,
      });
      setOpenDetailsTrasactionModal(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  };

  const getDataTRHistory = async (abortSignal) => {
    setLoading(true);
    setLoadMoreVisible(false);
    getTrxHistoryApi({
      params: {
        limit: 15,
        networkType: typeUser?.toUpperCase(),
        ...handleFiltersApiParams,
      },
      signal: abortSignal,
    })
      .then((res) => {
        setData(res?.data?.items);
        if (res?.data?.hasMore !== undefined && JSON.parse(res?.data?.hasMore) === true) {
          setLoadMoreVisible(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const loadMoreHandler = async () => {
    setLoadMoreLoading(true);
    const lastRecord = data[data.length - 1];
    getTrxHistoryApi({
      params: {
        limit: 15,
        networkType: typeUser?.toUpperCase(),
        startingAfter: lastRecord.id,
        ...handleFiltersApiParams,
      },
    })
      .then((res) => {
        setData((prevState) => [...prevState, ...res?.data?.items]);
        if (res.data.hasMore === 'false') {
          setLoadMoreVisible(false);
        }
      })
      .finally(() => setLoadMoreLoading(false));
  };

  const handleRemoveFilter = (name) => {
    if (name === 'dateRange') {
      setTrxFilters((prev) =>
        prev?.length ? prev?.filter((filter) => filter?.filterName !== 'dateRange') : [],
      );
    } else {
      setTrxFilters((prev) => (prev?.length ? prev?.filter((filter) => filter?.filterName !== name) : []));
    }
  };

  const handleResetFilters = () => setTrxFilters([]);

  const handleTrxFilterOnChange = (filter) => {
    const newFilterValue = {
      filterName: filter?.name,
      value: filter?.value,
      customRender: filter?.customRender,
    };
    setTrxFilters((prev) => {
      if (prev?.length) {
        const index = prev?.findIndex((prevFilter) => prevFilter?.filterName === filter?.name);
        if (index !== -1) {
          const deepCopy = cloneDeep(prev);
          deepCopy[index] = { ...deepCopy[index], value: filter?.value, customRender: filter?.customRender };
          return deepCopy;
        } else {
          return [...prev, newFilterValue];
        }
      } else {
        return [newFilterValue];
      }
    });
  };

  useEffect(() => {
    if (!typeUser || wallet?.id) return;
    if (trxFilters?.length > 0) {
      setTrxFilters([]);
    }
    const controller = new AbortController();
    getWalletsApi({ params: { type: typeUser?.toUpperCase(), limit: 0 }, signal: controller.signal }).then(
      (res) => setWallets(res.data?.items),
    );
    return () => {
      if (typeUser && !wallet?.id) {
        controller.abort();
      }
    };
  }, [typeUser, wallet?.id]);

  useEffect(() => {
    const controller = new AbortController();
    if (protocols && typeUser) {
      getDataTRHistory(controller.signal);
    }
    return () => {
      if (controller && typeUser && protocols) controller.abort();
    };
  }, [typeUser, wallet?.id, protocols, trxFilters]);

  if (isTabViewPermissionMissing) {
    return (
      <div className='table governance-layer-table'>
        <div className='table-row no-active no-prem'>
          <T>app.role.limitation</T>
        </div>
      </div>
    );
  }

  return (
    <div className='transaction-history-page'>
      <Suspense fallback={null}>
        <DesktopFiltersBar
          data={data}
          filters={trxFilters}
          setOpenFilters={setOpenFilters}
          handleRemoveFilter={handleRemoveFilter}
          handleResetFilters={handleResetFilters}
          setReferenceElement={setReferenceElement}
        />
      </Suspense>
      <Suspense fallback={null}>
        <MobileFiltersBar
          wallets={wallets}
          filters={trxFilters}
          walletId={wallet?.id}
          onChange={handleTrxFilterOnChange}
          handleResetFilters={handleResetFilters}
          handleRemoveFilter={handleRemoveFilter}
        />
      </Suspense>
      {openFilters && (
        <Suspense fallback={null}>
          <div
            className='transaction-filter-menu-popper'
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <TransactionFilterMenu
              walletId={wallet?.id}
              value={trxFilters}
              setOpenMenu={setOpenFilters}
              onChange={handleTrxFilterOnChange}
            />
          </div>
        </Suspense>
      )}
      <div
        className={`trx-history-table-wrapper ${trxFilters?.length ? 'filter-bar-visible' : ''} ${
          trialBannerVisible ? 'trial-banner-visible' : ''
        }`}
      >
        {/* {!isExportHistory ? ( */}
        <TransactionRequestsТable
          data={data}
          detailsHandler={getDetailsData}
          transactionKey='app.no.transactions.data'
          loadMoreHandler={loadMoreHandler}
          loadMore={loadMoreVisible}
          loading={loading}
          loadMoreLoading={loadMoreLoading}
        />
        {/* ) : (
            <ExportHistory
              data={data}
              loadMore={loadMoreVisible}
              loadMoreHandler={loadMoreHandler}
              transactionKey='app.no.transactions.data'
            />
          )} */}
      </div>
      {openDetailsTrasactionModal && (
        <Suspense fallback={null}>
          <DetailsTransactionRequest
            toggleFunction={setOpenDetailsTrasactionModal}
            data={detailsData}
            isHistory
          />
        </Suspense>
      )}
    </div>
  );
};
export default TransactionsHistory;
