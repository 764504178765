import { Suspense, lazy, useEffect, useState } from 'react';
import WalletTable from '../../components/Tables/Wallets/WalletTable';
import './Wallets.css';
import { T, t } from '../../components/T';
import { useDispatch, useSelector } from 'react-redux';
import { checkLimits } from '../../helpers/limits';
import { getWalletsBalancesApi } from '../../api/endpoints';
import { setMyUsage } from '../../redux/slices/userSlice';
import Button from '../../components/Button/Button';
import { ROUTE_WALLET, ROUTE_WALLETS } from '../../routes/routes';
import VModal from '../../components/Modal/VModal';
import VaultIcon from '../../components/VaultIcon/VaultIcon';
import { vaultTypes } from '../../helpers/constants';
const VTooltip = lazy(() => import('../../components/VTooltip/VTooltip'));
const LimitsModal = lazy(() => import('../../components/Modals/LimitsModal'));
const WalletBackUpModal = lazy(() => import('../../components/Modals/WalletBackUpModal'));
const GenerateAddressModal = lazy(() => import('../../components/Modals/GenerateAddressModal'));
const VaultCreationModal = lazy(
  () => import('../../components/Pages/Wallets/VaultCreationModal/VaultCreationModal'),
);
const CreateTransactionRequestNew = lazy(
  () => import('../../components/Modals/CreateTransactionRequestNew/CreateTransactionRequestNew'),
);

const Team = () => {
  // TODO: Translations!
  const dispatch = useDispatch();
  const limits = useSelector((state) => state.userReducer.limits);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [walletsData, setWalletsData] = useState([]);
  const [backUpModal, setBackUpModal] = useState(false);
  const [loading, setLoading] = useState(true);
  // Modal New Wallet
  const [addWalletModalOpen, setAddWalletModalOpen] = useState(false);
  // Modal New Deposit Address
  const [newDepositAddressModal, setNewDepositAddressModal] = useState(false);
  const [limitsWallet, setLimitsWallet] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [idSelect, setIdSelect] = useState('');
  // Modal new Transactions
  const [newTransactionRequestModal, setNewTransactionRequestModal] = useState(false);
  const [successModalNewWallet, setSuccessModalNewWallet] = useState(false);
  const createPermission = myProfile.premmisions?.includes('WALLETS_CREATE');
  const walletBackupPermission = myProfile.premmisions?.includes('WALLETS_BACKUP');

  useEffect(() => {
    if (typeUser) {
      requestWalletsData();
    }
  }, [typeUser]);

  const requestWalletsData = () => {
    setLoading(true);
    getWalletsBalancesApi(typeUser?.toUpperCase(), { params: { limit: 0 } })
      .then((res) => setWalletsData(res.data.items))
      .finally(() => setLoading(false));
  };
  // New wallet

  const openModalAddWallet = async () => {
    await checkLimits((usage) => dispatch(setMyUsage(usage)));
    const walletLimits = limits[ROUTE_WALLETS];
    if (walletLimits.from >= walletLimits.to) {
      setLimitsWallet(true);
    } else {
      setAddWalletModalOpen(true);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleSelectWalletId = async (id, type, vaultType) => {
    setIdSelect(id);
    if (type === 'receive') {
      await checkLimits((usage) => dispatch(setMyUsage(usage)));
      const addressLimits = limits[ROUTE_WALLET];
      if (addressLimits.from >= addressLimits.to) {
        setLimitModal(true);
      } else {
        setNewDepositAddressModal({ open: true, vaultType: vaultType });
      }
    } else {
      setNewTransactionRequestModal(true);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeSuccessModal = () => {
    setSuccessModalNewWallet(false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='team-page wallets'>
      <div className='header-table'>
        <div className='button-holder'>
          <Button
            size='md'
            onClick={openModalAddWallet}
            disabled={!createPermission}
            data-tooltip-id='create-wallet-button'
            data-tooltip-content={t(lng, 'app.role.limitation')}
          >
            {!createPermission && (
              <Suspense fallback={null}>
                <VTooltip id='create-wallet-button' />
              </Suspense>
            )}
            <i className='icon-billing-history' />
            <T>app.create.new.wallet</T>
          </Button>
        </div>
      </div>
      <WalletTable data={walletsData} handleSelectWalletId={handleSelectWalletId} loading={loading} />
      {addWalletModalOpen && (
        <div className='vaults-modal-wrapper'>
          <Suspense fallback={null}>
            <VaultCreationModal
              closeCallback={() => setAddWalletModalOpen(false)}
              successCallback={(vault) => {
                setAddWalletModalOpen(false);
                setIdSelect(vault);
                setSuccessModalNewWallet(true);
                requestWalletsData();
                checkLimits((usage) => dispatch(setMyUsage(usage)));
              }}
            />
          </Suspense>
        </div>
      )}
      {limitModal && (
        <Suspense fallback={null}>
          <LimitsModal toggleFunction={setLimitModal} modalName='app.addresses.limit' />
        </Suspense>
      )}

      {limitsWallet && (
        <Suspense fallback={null}>
          <LimitsModal
            toggleFunction={setLimitsWallet}
            modalName={typeUser === 'main' ? 'app.main.wallets.limit' : 'app.test.wallets.limit'}
          />
        </Suspense>
      )}
      {successModalNewWallet && (
        <VModal classHandler='vault-create-modal-suceess' toggleFunction={closeSuccessModal}>
          <div className='vault-modal-success-wrapper'>
            <VaultIcon
              id='newly-created-vault'
              name={idSelect?.name}
              color={idSelect?.color}
              vaultType={idSelect?.vaultType}
              className='vault-modal-success-wallet-icon'
              tooltipClassName='vault-modal-success-wallet-icon-tooltip'
              secondaryIconClassName='vault-modal-success-wallet-secondary-icon'
            />
            <div className='vault-modal-success-title'>
              <T>app.sussess.created</T>
            </div>
            <div className='vault-modal-success-name'>{idSelect?.name}</div>
            <div className='wallets-backup-wallet-button-wrapper'>
              <Button
                size='md'
                disabled={!walletBackupPermission}
                fullWidth
                onClick={() => {
                  setSuccessModalNewWallet(false);
                  setBackUpModal(true);
                }}
                data-tooltip-id='backup-wallet-button'
                data-tooltip-content={t(lng, 'app.role.limitation')}
              >
                {!walletBackupPermission && (
                  <Suspense fallback={null}>
                    <VTooltip id='backup-wallet-button' />
                  </Suspense>
                )}
                <i className='icon-wallets' />
                <T>app.wallet.backup</T>
              </Button>
            </div>
            <Button variant='close' size='md' fullWidth onClick={closeSuccessModal}>
              <T>app.close</T>
            </Button>
          </div>
        </VModal>
      )}
      {backUpModal && (
        <Suspense fallback={null}>
          <WalletBackUpModal
            editData={idSelect}
            toggleFunction={setBackUpModal}
            setBeckUp={requestWalletsData}
          />
        </Suspense>
      )}
      {newDepositAddressModal?.open && (
        <Suspense fallback={null}>
          <GenerateAddressModal
            id={idSelect.id}
            toggleFunction={() => setNewDepositAddressModal({ open: false })}
            type={
              newDepositAddressModal?.vaultType?.toLowerCase() === vaultTypes.SMART
                ? 'smart-deposit'
                : 'deposit'
            }
            walletType={newDepositAddressModal?.vaultType?.toLowerCase()}
          />
        </Suspense>
      )}
      {newTransactionRequestModal && (
        <Suspense fallback={null}>
          <CreateTransactionRequestNew
            toggleFunction={setNewTransactionRequestModal}
            prefilledWallet={idSelect}
          />
        </Suspense>
      )}
    </div>
  );
};

export default Team;
