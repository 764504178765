import EmailConfirmation from '../../assets/icons/email-conformation.svg';
import MobileSync from '../../assets/icons/mobile-sync.svg';
import deleteIcon from '../../assets/icons/delete-icon.svg';
import editIcon from '../../assets/icons/pencil-icon.svg';
import ActiveIcon from '../../assets/icons/statuses/status-signed.svg';
import { rolesShow } from '../../helpers/roles';
import ActionMenu from '../ActionMenu/ActionMenu';
import { T, t } from '../T';
import { useSelector } from 'react-redux';
import { STATE_DASHBOARD } from '../../redux/slices/authSlice';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '../Skeleton/Skeleton';
import { Suspense, lazy, useEffect, useState } from 'react';
const VTooltip = lazy(() => import('../VTooltip/VTooltip'));

const TeamActiveTable = ({
  data = [],
  editHadler,
  removeHandler,
  rolesAll = [],
  removeDeviceHandler,
  loading,
}) => {
  const owner = rolesAll?.filter((i) => {
    return i?.name === 'owner';
  })[0]?.id;
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [vaultodyDateFunc, setVaultodyDateFunc] = useState();

  const stautsShow = (stautsUser) => {
    if (stautsUser === 'verify-email') {
      return (
        <div className='status-bar'>
          <img className='status-bar-icon' src={EmailConfirmation} alt='Email confirmation' />
          <div className='status-name'>
            <T>app.email.confiramation</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'register-mobile') {
      return (
        <div className='status-bar'>
          <img className='status-bar-icon' src={MobileSync} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.mobile.sync</T>
          </div>
        </div>
      );
    } else if (stautsUser === 'deleted') {
      return (
        <div className='status-bar'>
          <div className='status-name'>
            <T>app.deleted</T>
          </div>
        </div>
      );
    } else {
      return (
        <div className='status-bar'>
          <img className='status-bar-icon' src={ActiveIcon} alt='Mobile sync' />
          <div className='status-name'>
            <T>app.active</T>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    const loadVaultodyDate = async () => {
      const module = await import('../../helpers/date');
      setVaultodyDateFunc(() => module.vaultodyDate);
    };
    loadVaultodyDate();
  }, []);

  return (
    <div
      className={`table governance-layer-table team-table ${
        trialBannerVisible ? 'trial-banner-visible' : ''
      }`}
    >
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='team-table-data name'>
            <T>app.name.email</T>
          </div>
          <div className='team-table-data role'>
            <T>app.roles</T>
          </div>
          <div className='team-table-data date'>
            <T>app.date.created</T>
          </div>
          <div className='team-table-data date'>
            <T>app.last.signed</T>
          </div>
          <div className='team-table-data status'>
            <T>app.status</T>
          </div>
          <div className='team-table-data settings' />
        </div>
        {loading && (
          <div className='loading-full-height-team'>
            <Skeleton rowHeight={isMobile ? 250 : 119} padding={isMobile ? 20 : 50} />
          </div>
        )}
        {!loading &&
          data.map((dataItem, key) => {
            return (
              <div key={key} className='table-row data'>
                <div className='team-table-data name'>
                  <span className='mobile-cell-title'>
                    <T>app.name.email</T>
                  </span>
                  <div>
                    <div className='name-data'>
                      <div className='icon-wrapper'>
                        <i className='icon-contact' />
                      </div>
                      <div className='name-right'>
                        <div className='name-team-page'>
                          <span data-tooltip-id='team-person-name' data-tooltip-content={dataItem?.name}>
                            <Suspense fallback={null}>
                              <VTooltip id='team-person-name' />
                            </Suspense>
                            {dataItem.name}
                          </span>
                        </div>
                        <div className='creator-team-page'>
                          <span data-tooltip-id='team-person-email' data-tooltip-content={dataItem?.email}>
                            <Suspense fallback={null}>
                              <VTooltip id='team-person-email' />
                            </Suspense>
                            {dataItem.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='team-table-data role'>
                  <span className='mobile-cell-title'>
                    <T>app.roles</T>
                  </span>
                  {rolesShow(rolesAll, dataItem.roles)}
                </div>
                <div className='team-table-data date'>
                  <span className='mobile-cell-title'>
                    <T>app.date.created</T>
                  </span>
                  {vaultodyDateFunc ? vaultodyDateFunc(dataItem.createdAt, 'full') : null}
                </div>
                <div className='team-table-data date'>
                  <span className='mobile-cell-title'>
                    <T>app.last.signed</T>
                  </span>
                  {dataItem.lastSignInAt === null
                    ? 'N/A'
                    : vaultodyDateFunc
                      ? vaultodyDateFunc(dataItem.lastSignInAt, 'full')
                      : null}
                </div>
                <div className='team-table-data status'>
                  <span className='mobile-cell-title'>
                    <T>app.status</T>
                  </span>
                  {stautsShow(dataItem.status)}
                </div>
                <div className='team-table-data settings'>
                  {dataItem.status === STATE_DASHBOARD &&
                    dataItem.id !== myProfile.id &&
                    dataItem.roles.some((role) => {
                      return role !== owner;
                    }) && (
                      <>
                        <span className='mobile-cell-title'>
                          <T>app.settings</T>
                        </span>
                        <div>
                          <ActionMenu
                            data={dataItem}
                            items={[
                              {
                                name: t(lng, 'app.change.roles'),
                                icon: editIcon,
                                permission: Object.values(myProfile.premmisions).includes(
                                  'TEAM_MEMBERS_EDIT',
                                ),
                                callback: () => editHadler(dataItem),
                              },
                              {
                                name: t(lng, 'app.remove.device'),
                                icon: deleteIcon,
                                permission: true,
                                callback: () => removeDeviceHandler(dataItem),
                              },
                              {
                                name: t(lng, 'app.remove'),
                                icon: deleteIcon,
                                permission: Object.values(myProfile.premmisions).includes(
                                  'TEAM_MEMBERS_DELETE',
                                ),
                                callback: removeHandler,
                              },
                            ]}
                          />
                        </div>
                      </>
                    )}
                </div>
              </div>
            );
          })}
        {!loading && data.length === 0 && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.active.team</T>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamActiveTable;
