import { T } from '../T';
import './LoadMore.css';
import ReactLoading from 'react-loading';

const LoadMore = ({ loadMoreHandler = () => {}, isLoading }) => {
  return (
    <div className='load-more'>
      <div className='load-more-button' onClick={loadMoreHandler}>
        {isLoading ? (
          <div className='loading' style={{ background: 'transparent' }}>
            <ReactLoading width={25} height={25} type='spin' color='##020D1C' />
          </div>
        ) : (
          <T>app.loadmore</T>
        )}
      </div>
    </div>
  );
};
export default LoadMore;
