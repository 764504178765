import { Suspense, lazy, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { getCryptoIconFromBucket } from '../../../helpers/protocolIcons/protocolIcons';
import { T, t } from '../../T';
import { checkLimits } from '../../../helpers/limits';
import Filter from '../../Filter/Filter';
import {
  getAssetsByAddressApi,
  getBlockchainProtocolsApi,
  getWalletAddressesApi,
  linkAddressApi,
} from '../../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setMyUsage } from '../../../redux/slices/userSlice';
import Button from '../../Button/Button';
import Accordion from '../../Accordion/Accordion';
import AssetAccordionDetails from '../../Pages/Assets/AssetAccordionDetails/AssetAccordionDetails';
import LoadMore from '../../LoadMore/LoadMore';
import AddressAccordionTitle from '../../Pages/Wallets/AddressAccordionTitle';
import { ROUTE_WALLET } from '../../../routes/routes';
import Blockchain from '../../Blockchain/Blockchain';
import CopyButton from '../../CopyButton/CopyButton';
import linkImage from '../../../assets/images/link-image.svg';
import { vaultTypes } from '../../../helpers/constants';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '../../Skeleton/Skeleton';
const VTooltip = lazy(() => import('../../VTooltip/VTooltip'));
const LimitsModal = lazy(() => import('../../Modals/LimitsModal'));
const BasicModal = lazy(() => import('../../Modals/BasicModal/BasicModal'));
const GenerateAddressModal = lazy(() => import('../../Modals/GenerateAddressModal'));

const WalletAdressesTable = ({ id, backup, walletData }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const limits = useSelector((state) => state.userReducer.limits);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [dataAdresses, setDataAdresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState([]);
  const [addressAssets, setAddressAssets] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [selectedProtocols, setSelectedProtocols] = useState();
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [protocolsWithIcons, setProtocolsWithIcons] = useState();
  const [linkAddressModal, setLinkAddressModal] = useState({
    open: false,
    address: {},
  });
  const addressTypeMapping = {
    smart: ['smart-deposit'],
    automation: ['smart-deposit', 'deposit'],
  };
  const isSmartOrAutomationVault =
    walletData?.vaultType?.toLowerCase() === vaultTypes.SMART ||
    walletData?.vaultType?.toLowerCase() === vaultTypes.AUTOMATION;

  const addressCreatePermission = myProfile.premmisions?.includes('WALLETS_ADDRESS_CREATE');

  useEffect(() => {
    getBlockchainProtocolsApi({
      params: { vaultsTypes: [walletData?.vaultType?.toUpperCase()], networkType: typeUser?.toUpperCase() },
    }).then((res) =>
      setProtocolsWithIcons(
        res?.data?.items?.map((item) => ({
          ...item,
          icon: getCryptoIconFromBucket(item?.currency?.toLowerCase()),
        })),
      ),
    );
  }, []);

  const handleLinkAddress = async () => {
    return linkAddressApi(walletData?.id, {
      item: {
        address: linkAddressModal?.address?.address,
        blockchain: linkAddressModal?.address?.blockchain,
      },
    }).then(() => {
      setLinkAddressModal({ open: false });
      getDataAdresses();
    });
  };

  const handleProtocolsFilterChange = (selectedProtocols) => {
    setSelectedProtocols(selectedProtocols);
  };

  const getAssetsByAddress = (addressBlockchain, addressId, accordionIndex) => {
    setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: true }));
    getAssetsByAddressApi(id, addressBlockchain, addressId)
      .then((res) => {
        const sortedAssets = res?.data?.items?.sort((a, b) => {
          return b?.assetData?.amount * b?.exchangeRate - a?.assetData?.amount * a?.exchangeRate;
        });
        setAddressAssets((prev) => ({ ...prev, [accordionIndex]: sortedAssets }));
      })
      .finally(() => setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: false })));
  };

  const getDataAdresses = () => {
    setLoading(true);
    getWalletAddressesApi(id, {
      params: {
        limit: 15,
        skip: 0,
        ...(addressTypeMapping[walletData?.vaultType?.toLowerCase()]
          ? { addressTypes: addressTypeMapping[walletData?.vaultType?.toLowerCase()] }
          : {}),
      },
    })
      .then((res) => {
        setDataAdresses(res.data.items);
        if (res.data.items.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        setLoadMoreVisible(res?.data?.skip + res?.data?.limit < res?.data?.total);
      })
      .catch(() => setLoadMoreVisible(false))
      .finally(() => setLoading(false));
  };

  const loadMoreHandler = async () => {
    setLoadMoreLoading(true);
    getWalletAddressesApi(id, {
      params: {
        limit: 15,
        skip: dataAdresses?.length || 0,
        ...(addressTypeMapping[walletData?.vaultType?.toLowerCase()]
          ? { addressTypes: addressTypeMapping[walletData?.vaultType?.toLowerCase()] }
          : {}),
        ...(!selectedProtocols || selectedProtocols?.length === protocolsWithIcons?.length
          ? {}
          : {
              blockchain: selectedProtocols?.[0]?.blockchain,
            }),
      },
    })
      .then((res) => {
        setDataAdresses((prevState) => [...prevState, ...(res?.data?.items?.length ? res.data.items : [])]);
        if (res.data.items.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        setLoadMoreVisible(res?.data?.skip + res?.data?.limit < res?.data?.total);
      })
      .finally(() => setLoadMoreLoading(false));
  };

  const filterFunction = () => {
    setAccordionOpenIndexes([]);
    setAddressAssets(undefined);
    setLoading(true);
    getWalletAddressesApi(id, {
      params: {
        limit: 15,
        skip: 0,
        ...(addressTypeMapping[walletData?.vaultType?.toLowerCase()]
          ? { addressTypes: addressTypeMapping[walletData?.vaultType?.toLowerCase()] }
          : {}),
        ...(!selectedProtocols || selectedProtocols?.length === protocolsWithIcons?.length
          ? {}
          : {
              blockchain: selectedProtocols?.[0]?.blockchain,
            }),
      },
    })
      .then((res) => {
        setDataAdresses(res.data.items);
        if (res.data.items.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        setLoadMoreVisible(res?.data?.skip + res?.data?.limit < res?.data?.total);
      })
      .catch(() => setLoadMoreVisible(false))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedProtocols?.length === 0) {
      setDataAdresses([]);
      return;
    }
    filterFunction();
  }, [selectedProtocols]);

  useEffect(() => {
    getDataAdresses();
  }, []);

  return (
    <>
      <div className='team-page wallets wallets-address'>
        {linkAddressModal?.open && (
          <Suspense fallback={null}>
            <BasicModal
              submitButtonText={t(lng, 'app.link.address')}
              submitCallback={handleLinkAddress}
              icon={linkImage}
              title={t(lng, 'app.are.sure.link.address')}
              subTitle={t(lng, 'app.link.address.sub.title')}
              closeCallback={() => {
                setLinkAddressModal(false);
              }}
            >
              <div className='link-address-info-wrapper'>
                <div className='link-address-info-row'>
                  <span className='link-address-info-text'>
                    <T>app.address.name</T>
                  </span>
                  <span className='link-address-info-sub-text'>{linkAddressModal.address?.name}</span>
                </div>
                <div className='link-address-info-row'>
                  <span className='link-address-info-text'>
                    <T>app.blockchain</T>
                  </span>
                  <Blockchain
                    blockchain={protocols
                      ?.find(
                        (protocol) =>
                          protocol?.blockchain?.toLowerCase() ===
                          linkAddressModal?.address?.blockchain?.toLowerCase(),
                      )
                      ?.currency?.toLowerCase()}
                    showText
                    symbol={protocols
                      ?.find(
                        (protocol) =>
                          protocol?.blockchain?.toLowerCase() ===
                          linkAddressModal?.address?.blockchain?.toLowerCase(),
                      )
                      ?.currency?.toLowerCase()}
                  />
                </div>
                <div className='link-address-info-row'>
                  <span className='link-address-info-text'>
                    <T>app.deposit.address</T>
                  </span>
                  <div className='wallet-addresses-link-address-wrapper'>
                    <span>{linkAddressModal?.address?.address}</span>
                    <CopyButton element={linkAddressModal?.address?.address} />
                  </div>
                </div>
                <div className='link-address-info-row'>
                  <span className='link-address-info-warning-text'>
                    {`${t(
                      lng,
                      `app.linking.will.cost.${linkAddressModal?.address?.blockchain?.toLowerCase()}`,
                    )} ${t(lng, 'app.gas.fees')}`}
                  </span>
                </div>
              </div>
            </BasicModal>
          </Suspense>
        )}
        <div className='wallet-button-filters-wrapper'>
          <div className='search-bar'>
            <Filter
              items={protocolsWithIcons}
              placeholder='app.filters.blockchains'
              selectAllField
              displayIcon
              roundedIcon
              customIconKey='icon'
              onChange={handleProtocolsFilterChange}
              selectedItems={selectedProtocols || []}
              dataKey='blockchain'
              dataNameKey='publicName'
            />
          </div>
          <div className='button-holder'>
            <Button
              disabled={!addressCreatePermission || !backup}
              size='md'
              onClick={async () => {
                await checkLimits((usage) => dispatch(setMyUsage(usage)));
                if (limits[ROUTE_WALLET].from >= limits[ROUTE_WALLET].to) {
                  setLimitModal(true);
                } else {
                  setOpenModal(true);
                }
              }}
              data-tooltip-id='address-create-button'
              data-tooltip-content={
                !addressCreatePermission ? t(lng, 'app.role.limitation') : t(lng, 'app.backup.secure')
              }
            >
              {(!addressCreatePermission || !backup) && (
                <Suspense fallback={null}>
                  <VTooltip id='address-create-button' />
                </Suspense>
              )}
              <i className='icon-wallets' />
              <T>app.generate.new.address</T>
            </Button>
          </div>
        </div>
        <div className={`accordion-table-parent-wallets ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
          <div className='table-content-wallet-addresses-table'>
            <div className='wallet-addresses-tablee-header'>
              <div
                className={`addresses-table-data name${
                  isSmartOrAutomationVault ? '-link' : ''
                } wallet-addresses-table-header`}
              >
                <T>app.address.name</T>
              </div>
              <div
                className={`addresses-table-data blockchain${
                  isSmartOrAutomationVault ? '-link' : ''
                } wallet-addresses-table-header`}
              >
                <T>app.blockchain</T>
              </div>
              <div
                className={`addresses-table-data trx-address${
                  isSmartOrAutomationVault ? '-link' : ''
                } wallet-addresses-table-header`}
              >
                <T>app.address</T>
              </div>
              <div
                className={`addresses-table-data created-at${
                  isSmartOrAutomationVault ? '-link' : ''
                } wallet-addresses-table-header`}
              >
                <T>app.created</T>
              </div>
              {(walletData?.vaultType?.toLowerCase() === vaultTypes.SMART ||
                walletData?.vaultType?.toLowerCase() === vaultTypes.AUTOMATION) && (
                <div className='addresses-table-data link-status wallet-addresses-table-header'>
                  <T>app.link.status</T>
                </div>
              )}
              <div
                className={`addresses-table-data settings${
                  isSmartOrAutomationVault ? '-link' : ''
                } wallet-addresses-table-header`}
              />
            </div>
            {loading && (
              <div className='loading-full-height'>
                <Skeleton rowHeight={isMobile ? 306 : 103} padding={isMobile ? 20 : 50} />
              </div>
            )}
            {!loading &&
              dataAdresses.map((address, index) => (
                <Accordion
                  key={index}
                  open={accordionOpenIndexes?.includes(index)}
                  onOpenCallback={(isAccordionOpened) => {
                    if (!isAccordionOpened) {
                      setAccordionOpenIndexes((prev) => [...prev, index]);
                      getAssetsByAddress(address.blockchain, address.address, index);
                    } else {
                      setAccordionOpenIndexes((prev) =>
                        prev?.filter((accordionIndex) => accordionIndex !== index),
                      );
                    }
                  }}
                  titleComponent={
                    <AddressAccordionTitle
                      linkAddressCallback={(e, address) => {
                        e.stopPropagation();
                        setLinkAddressModal({ open: true, address: address });
                      }}
                      address={address}
                      vault={walletData}
                    />
                  }
                  detailsComponent={
                    loadingAssets[index] ? (
                      <div className='team-page wallets wallets-address'>
                        <div className='loading'>
                          <ReactLoading type='spin' color='##020D1C' />
                        </div>
                      </div>
                    ) : (
                      <AssetAccordionDetails
                        asset={{ ...addressAssets?.[index], network: address?.network }}
                        address={address}
                        walletData={walletData}
                        blockchain={address?.blockchain}
                        rows={addressAssets?.[index] || []}
                        type='assets'
                      />
                    )
                  }
                />
              ))}
            {!loading && loadMoreVisible && (
              <LoadMore isLoading={loadMoreLoading} loadMoreHandler={loadMoreHandler} />
            )}
            {!loading && dataAdresses.length === 0 && (
              <div className='empty-addresses-label'>
                <div>
                  <T>app.no.addresess</T>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <Suspense fallback={null}>
          <GenerateAddressModal
            id={id}
            toggleFunction={setOpenModal}
            sussessFunction={getDataAdresses}
            type={walletData?.vaultType?.toLowerCase() === vaultTypes.SMART ? 'smart-deposit' : 'deposit'}
            walletType={walletData?.vaultType?.toLowerCase()}
          />
        </Suspense>
      )}
      {limitModal && (
        <Suspense fallback={null}>
          <LimitsModal toggleFunction={setLimitModal} modalName='app.addresses.limit' />
        </Suspense>
      )}
    </>
  );
};
export default WalletAdressesTable;
