import { T } from '../../T';
import ReactLoading from 'react-loading';
import JSONPretty from 'react-json-pretty';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { ReactComponent as ResendIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/green-checkmark.svg';
import { setPage } from '../../../redux/slices/globalSlice';
import {
  getWebhookCallbackLogDetailsApi,
  getWebhookCallbackLogsApi,
  getWebhookEventsApi,
  resendWebhookApi,
} from '../../../api/endpoints';
import Button from '../../Button/Button';
import Skeleton from '../../Skeleton/Skeleton';

const WebhookHistory = () => {
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [activeTab, isTabViewPermissionMissing] = useOutletContext();
  const { id } = useParams();
  const dispatch = useDispatch();
  const mobileScrollRef = useRef(null);
  const [webhooksEvents, setWebHooksEvents] = useState([]);
  const [callbacks, setCallBacks] = useState([]);
  const [callbackData, setCallBackData] = useState([]);
  const [callbacksLoading, setCallbacksLoading] = useState(true);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [callbackDetailsLoading, setCallbackDetailsLoading] = useState(false);
  const [vaultodyDateFunc, setVaultodyDateFunc] = useState();

  const handleResendWebhook = (callbackId) => {
    setResendLoading(true);
    resendWebhookApi(id, callbackId)
      .then((res) => {
        if (res?.data?.data?.item?.success) {
          setResendSuccess(true);
        }
      })
      .finally(() => setResendLoading(false));
  };

  const getCallBacks = (id, abortSignal) => {
    if (activeTab && id) {
      setCallbacksLoading(true);
      if (activeTab === 'all') {
        getWebhookCallbackLogsApi(id, { signal: abortSignal })
          .then((res) => {
            setCallBacks(res.data.items);
            if (res?.data?.items?.[0].id) {
              getCallbacksData(id, res.data.items[0].id);
            }
          })
          .finally(() => setCallbacksLoading(false));
      } else {
        getWebhookCallbackLogsApi(id, { params: { success: activeTab === 'succeeded' }, signal: abortSignal })
          .then((res) => {
            setCallBacks(res.data.items);
            if (res?.data?.items?.[0].id) {
              getCallbacksData(id, res.data.items[0].id);
            }
          })
          .finally(() => setCallbacksLoading(false));
      }
    }
  };

  const getCallbacksData = (idWebHooks, idCallBack) => {
    setResendSuccess(false);
    setResendLoading(false);
    setCallbackDetailsLoading(true);
    getWebhookCallbackLogDetailsApi(idWebHooks, idCallBack)
      .then((res) => {
        setCallBackData(res.data.item);
      })
      .finally(() => setCallbackDetailsLoading(false));
    mobileScrollRef.current?.scrollIntoView();
  };

  const getPermissibleRoutes = () => {
    getWebhookEventsApi().then((res) => {
      setWebHooksEvents(res.data.item);
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!isTabViewPermissionMissing) {
      getCallBacks(id, controller?.signal);
      getPermissibleRoutes();
    }
    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [id, activeTab, isTabViewPermissionMissing]);

  useEffect(() => {
    if (!isTabViewPermissionMissing) {
      dispatch(setPage(callbackData?.callbackUrl));
    }
  }, [callbackData?.callbackUrl, isTabViewPermissionMissing]);

  useEffect(() => {
    const loadVaultodyDate = async () => {
      const module = await import('../../../helpers/date');
      setVaultodyDateFunc(() => module.vaultodyDate);
    };
    loadVaultodyDate();
  }, []);

  if (isTabViewPermissionMissing) {
    return (
      <div className='table governance-layer-table'>
        <div className='table-row no-active no-prem'>
          <T>app.role.limitation</T>
        </div>
      </div>
    );
  }

  return (
    <div className='developers'>
      <div className={`webhooks-callbacks ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='webhooks-endpoints'>
          {callbacksLoading && (
            <div className='loading-full-height'>
              <Skeleton rowHeight={68} padding={20} />
            </div>
          )}
          {!callbacksLoading &&
            callbacks.map((i, key) => {
              return (
                <div
                  className={`webhooks-table-row caption ${i.id === callbackData.id ? 'active' : ''}`}
                  key={key}
                  onClick={() => getCallbacksData(id, i.id)}
                >
                  <div className='webhooks-table-data icon'>
                    <div className='icon-holder'>
                      {i.success && (
                        <div className='status-bar'>
                          <span className='status-icon approved' />
                        </div>
                      )}
                      {!i.success && (
                        <div className='status-bar'>
                          <span className='status-icon rejected' />
                        </div>
                      )}
                    </div>
                    {webhooksEvents[i.event]}
                  </div>
                  <div className='webhooks-table-data date'>
                    {vaultodyDateFunc ? vaultodyDateFunc(i.executionTime, 'full') : null}
                  </div>
                </div>
              );
            })}
          {!callbacksLoading && callbacks.length === 0 && (
            <div className='no-data'>
              <T>app.no.webhooks.for.this.type</T>
            </div>
          )}
        </div>
        {!callbacksLoading && callbacks.length !== 0 && !callbackDetailsLoading ? (
          <div className='jsonInfo' ref={mobileScrollRef}>
            <div className='title-webhooks'>
              {webhooksEvents[callbackData.event]}
              <Button
                variant='neutral'
                size='sm'
                icon={
                  resendSuccess ? (
                    <CheckmarkIcon />
                  ) : resendLoading ? (
                    <ReactLoading
                      type='spin'
                      color='##020D1C'
                      width={14}
                      height={14}
                      className='resend-button-loading-icon'
                    />
                  ) : (
                    <ResendIcon className='verify-email-button-icon' />
                  )
                }
                disabled={resendSuccess}
                onClick={() => handleResendWebhook(callbackData?.id)}
              >
                {resendSuccess ? <T>app.sent</T> : <T>app.resend</T>}
              </Button>
            </div>
            <div className='title'>
              <T>app.request</T>
            </div>
            <div className={`httpcode ${callbackData?.httpStatusCode?.startsWith('2') ? 'ok' : 'error'}`}>
              {callbackData.httpStatusCode} {callbackData?.httpStatusCode?.startsWith('2') ? 'OK' : 'ERROR'}
            </div>
            <div className='title request'>
              <T>app.http.response</T>
            </div>
            <JSONPretty data={callbackData.body} />
          </div>
        ) : (
          <div className='callback-logs-loader'>
            <Skeleton rowHeight={48} padding={20} margin={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WebhookHistory;
