import { useSelector } from 'react-redux';
import { t } from '../T';
import './Balance.css';
import { currency } from '../../helpers/currency';
import Skeleton from '../Skeleton/Skeleton';
import { lazy, Suspense } from 'react';
const VTooltip = lazy(() => import('../VTooltip/VTooltip'));

const Balance = ({ avable, total, aml, allocated }) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);

  const balancesConfig = [
    {
      title: t(lng, 'app.available.balance'),
      count: avable,
      tooltip: t(lng, 'app.available.balance.desc'),
    },
    {
      title: t(lng, 'app.total.balance'),
      count: total,
      tooltip: t(lng, 'app.total.balance.desc'),
    },
    {
      title: t(lng, 'app.aml.blocked'),
      count: aml,
      tooltip: t(lng, 'app.aml.blocked.desc'),
    },
    {
      title: t(lng, 'app.allocated'),
      count: allocated,
      tooltip: t(lng, 'app.allocated.desc'),
    },
  ];

  return (
    <div className='balances'>
      {balancesConfig.map((balance, key) => {
        return (
          <div key={key} className='balance'>
            <div className='title'>
              <div>{balance.title}</div>
              <div className='question'>
                <div
                  className='icon'
                  data-tooltip-id={`balance-${key}`}
                  data-tooltip-content={balance?.tooltip}
                >
                  <Suspense fallback={null}>
                    <VTooltip id={`balance-${key}`} />
                  </Suspense>
                  <span className='balance-question-mark'>?</span>
                </div>
              </div>
            </div>
            {balance?.count !== undefined ? (
              <div className='count'>{currency(balance?.count)}</div>
            ) : (
              <div className='assets-balance-skeleton-wrapper'>
                <Skeleton rowHeight={30} padding={0} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Balance;
