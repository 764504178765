import { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import useWindowResize from '../../helpers/hooks/useWindowResize';

const VModal = ({
  children,
  toggleFunction,
  classHandler,
  hideCloseIcon,
  closeOnOutsideClick,
  scrollTrigger,
}) => {
  const wrapper = useRef('modal');
  const scrollRef = useRef(null);
  const windowSize = useWindowResize();
  const [modalOverflowingViewPort, setModalOverflowingViewPort] = useState(false);

  useClickOutside(wrapper, () => {
    if (closeOnOutsideClick) {
      return toggleFunction();
    }
    return;
  });

  useEffect(() => {
    if (windowSize.height < wrapper.current?.clientHeight) {
      setModalOverflowingViewPort(true);
    } else {
      setModalOverflowingViewPort(false);
    }
  }, [windowSize]);

  useEffect(() => {
    document.querySelector('body').classList.add('modal-active');
    return () => {
      document.querySelector('body').classList.remove('modal-active');
    };
  }, []);

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        toggleFunction();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (scrollTrigger) {
      setTimeout(() => {
        scrollRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 200);
    }
  }, [scrollTrigger]);

  return (
    <div className={`v-modal ` + classHandler}>
      <div className='overlay' ref={scrollRef}>
        <div className={`v-modal-content ${modalOverflowingViewPort ? 'overflowing' : ''}`} ref={wrapper}>
          {!hideCloseIcon && (
            <div
              className='icon icon-close'
              onClick={() => {
                toggleFunction();
              }}
            />
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default VModal;
