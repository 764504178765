import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EditWallet from '../../EditWallet/EditWallet';
import 'react-circular-progressbar/dist/styles.css';
import { t } from '../../T';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter-icon.svg';
import AccLimits from '../../AccLimits/AccLimits';
import { setPage, setTrxFiltersModalOpen } from '../../../redux/slices/globalSlice';
import {
  ROUTE_DEVELOPERS,
  ROUTE_HOME,
  ROUTE_LOGIN_HISTORY,
  ROUTE_PROFILE,
  ROUTE_TEAM,
  ROUTE_TRX_HISTORY,
  ROUTE_TRX_POLICY,
  ROUTE_TRX_REQUESTS,
  ROUTE_TRX_REQUESTS_SHELF,
  ROUTE_WALLET,
  ROUTE_WALLETS,
  ROUTE_WEBHOOK,
} from '../../../routes/routes';

const TitleBox = ({ name }) => {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.globalReducer.page);
  const limits = useSelector((state) => state.userReducer.limits);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const location = useLocation();
  const navigate = useNavigate();
  const [addressLimit, setAddressLimit] = useState(true);

  useEffect(() => {
    if (location.hash === '#addresses') {
      setAddressLimit(true);
    } else {
      setAddressLimit(false);
    }
  }, [location.hash]);

  // Page configoration
  const pages = {
    [ROUTE_HOME]: t(lng, 'app.assets'),
    [ROUTE_WALLETS]: t(lng, 'app.wallets'),
    [ROUTE_TRX_REQUESTS]: t(lng, 'app.transaction.req'),
    [ROUTE_TRX_REQUESTS_SHELF]: t(lng, 'app.transaction.shelf'),
    [ROUTE_TRX_HISTORY]: t(lng, 'app.trans.history'),
    [ROUTE_TRX_POLICY]: t(lng, 'app.gov.leayer'),
    [ROUTE_TEAM]: t(lng, 'app.team'),
    [ROUTE_DEVELOPERS]: t(lng, 'app.developers'),
    [ROUTE_PROFILE]: t(lng, 'app.menu.profile'),
    [ROUTE_LOGIN_HISTORY]: t(lng, 'app.menu.history'),
  };
  return (
    <div className={`title-box ${`/${location.pathname.split('/')[1]}` === ROUTE_WALLET ? 'edit-mode' : ''}`}>
      {location.pathname?.includes(`${ROUTE_WEBHOOK}/`) && (
        <div
          className='exit-webhook'
          onClick={() => {
            navigate(`${ROUTE_DEVELOPERS}#webhooks`);
            dispatch(setPage('Developers'));
          }}
        >
          <i className='icon icon-arrow-right' />
        </div>
      )}
      {`/${location.pathname.split('/')[1]}` === ROUTE_WALLET && page !== '' ? (
        <EditWallet
          setPage={(pageName) => dispatch(setPage(pageName))}
          id={location.pathname.split('/')[2]}
          page={page}
        />
      ) : (
        <div className='title'>{page ? page : pages[name]}</div>
      )}
      {(location.pathname === ROUTE_TRX_HISTORY ||
        (location.pathname?.includes(`${ROUTE_WALLET}/`) && location.hash === '#transactions-history')) && (
        <FilterIcon
          width={22}
          height={22}
          onClick={() => dispatch(setTrxFiltersModalOpen(true))}
          className='mobile-trx-filter-icon'
        />
      )}
      {limits[name] && (
        <AccLimits page={page} pages={pages} name={name} from={limits[name]?.from} to={limits[name]?.to} />
      )}
      {addressLimit && (
        <AccLimits
          page={t(lng, 'app.addresses')}
          pages={pages}
          name={name}
          from={limits[ROUTE_WALLET]?.from}
          to={limits[ROUTE_WALLET]?.to}
        />
      )}
    </div>
  );
};
export default TitleBox;
