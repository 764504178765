import { Suspense, lazy, useEffect, useState } from 'react';
import GoverananceTable from '../../components/Tables/GovernanceTable';
import './GoverananceLayer.css';
import { T, t } from '../../components/T';
import { checkLimits } from '../../helpers/limits';
import Filter from '../../components/Filter/Filter';
import {
  getGovernanceActiveRulesApi,
  getGovernancePendingRulesApi,
  getTrxRequestsApproversApi,
  getWalletsApi,
} from '../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setMyUsage } from '../../redux/slices/userSlice';
import Button from '../../components/Button/Button';
import { ROUTE_TRX_POLICY } from '../../routes/routes';
const VTooltip = lazy(() => import('../../components/VTooltip/VTooltip'));
const GLViewModal = lazy(() => import('../../components/Modals/GLViewModal'));
const LimitsModal = lazy(() => import('../../components/Modals/LimitsModal'));
const CreateTransactionPolicyModal = lazy(
  () => import('../../components/Modals/CreateTransactionPolicyModal/CreateTransactionPolicyModal'),
);

// Governance Layer page
// TODO: In integrate tasks make a helper for translation and change all texts with values
const GovernanceLayer = () => {
  const dispatch = useDispatch();
  const limits = useSelector((state) => state.userReducer.limits);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const [choosenWallets, setChoosenWallets] = useState();
  const [tempData, setTempData] = useState();
  const [active, setActive] = useState(0);
  const [pending, setPending] = useState(0);
  const [tab, setTab] = useState();
  // Modal
  const [openAddEdit, setOpenAddEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [editData, setEditData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [limitsModal, setLimitsModal] = useState(false);
  const [allWallets, setAllWallets] = useState([]);
  const [selectWallets, setSelectWallets] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [loading, setLoading] = useState(true);
  const createPolicyPermission = myProfile.premmisions?.includes('GOVERNANCE_RULES_CREATE');

  const getActiveAndPendingRules = (controller) => {
    setLoading(true);
    getGovernancePendingRulesApi({
      params: {
        limit: 50,
        ...(choosenWallets?.length > 0 && choosenWallets?.length !== allWallets?.length
          ? {
              walletsIds: choosenWallets.map((wallet) => {
                return wallet.id;
              }),
            }
          : {}),
      },
      ...(controller ? { signal: controller.signal } : {}),
    }).then((res) => {
      const filter = res?.data?.items.filter((x) => x.wallets[0].type === typeUser?.toLocaleUpperCase());
      setPending(filter?.length);
      if (tab === 'pending') {
        setTempData(filter);
        setLoading(false);
      }
    });
    getGovernanceActiveRulesApi({
      params: {
        limit: 50,
        ...(choosenWallets?.length > 0 && choosenWallets?.length !== allWallets?.length
          ? {
              walletsIds: choosenWallets.map((wallet) => {
                return wallet.id;
              }),
            }
          : {}),
      },
      ...(controller ? { signal: controller.signal } : {}),
    }).then((res) => {
      const filter = res?.data?.items.filter((x) => x.wallets[0].type === typeUser?.toLocaleUpperCase());
      setActive(filter?.length);
      if (tab === 'active') {
        setTempData(filter);
        setLoading(false);
      }
    });
  };

  const handleChooseWallets = (chosenWallets) => {
    setChoosenWallets(chosenWallets);
  };

  // Modal logic
  const editHandlerData = (id) => {
    getTrxRequestsApproversApi().then((res) => setApprovers(res?.data?.items));
    setOpenAddEdit(true);
    const data = tempData.filter((i) => {
      return i.id === id;
    });
    setEditData(data[0]);
    setSelectWallets(data[0].wallets);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const viewHandler = (id) => {
    setOpenView(true);
    const data = tempData.filter((i) => {
      return i.id === id;
    });
    setViewData(data[0]);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeModal = () => {
    setOpenAddEdit(false);
    setEditData([]);
  };
  const closeViewModal = () => {
    setOpenView(false);
    setViewData([]);
  };

  const handleChangeTab = (e) => {
    const selectedTab = e.currentTarget.getAttribute('data-type');
    setTab(selectedTab);
    window.location.hash = selectedTab;
  };

  useEffect(() => {
    if (!window.location.hash) {
      window.location.hash = 'active';
    }
    setTab(window.location.hash?.replace('#', ''));
    getTrxRequestsApproversApi().then((res) => setApprovers(res?.data?.items));
  }, []);

  useEffect(() => {
    if (typeUser) {
      getWalletsApi({
        params: { type: typeUser?.toUpperCase(), limit: 0, vaultTypes: ['GENERAL', 'SMART'] },
      }).then((res) => {
        setAllWallets(res?.data?.items);
        setChoosenWallets(res?.data?.items);
      });
    }
  }, [typeUser]);

  useEffect(() => {
    // Need fix BE for WS messsage
    if (typeof messageSocket === 'object') {
      if (
        messageSocket?.data?.event === 'governance_rule_creation_approved' ||
        messageSocket?.data?.event === 'governance_rule_creation_rejected' ||
        messageSocket?.data?.event === 'governance_rule_update_approved' ||
        messageSocket?.data?.event === 'governance_rule_update_rejected'
      ) {
        closeModal();
        getActiveAndPendingRules();
        checkLimits((usage) => dispatch(setMyUsage(usage)));
      }
    }
  }, [messageSocket]);

  useEffect(() => {
    const controller = new AbortController();
    if (typeUser) {
      getActiveAndPendingRules(controller);
    }
    return () => {
      if (controller && typeUser) {
        controller.abort();
      }
    };
  }, [tab, typeUser, choosenWallets]);

  return (
    <div className={'governance-layer ' + tab}>
      <div className='governance-header'>
        <div className='tabs'>
          <div onClick={handleChangeTab} className='tab' data-type='active'>
            <T>app.active.rules</T> <span className='info-active'>{active > 9 ? '9+' : active}</span>
          </div>
          <div onClick={handleChangeTab} className='tab' data-type='pending'>
            <T>app.pending.rules</T> <span className='info-pending'>{pending > 9 ? '9+' : pending}</span>
          </div>
        </div>
      </div>
      <div className='governance-filters-wrapper'>
        <Filter
          items={allWallets}
          placeholder='app.filter.vaults'
          selectAllField
          displayIcon
          multiSelect
          onChange={handleChooseWallets}
          selectedItems={choosenWallets || []}
        />
        <div className='button-holder-policy'>
          <Button
            size='md'
            disabled={!createPolicyPermission}
            data-tooltip-id='create-trx-policy'
            data-tooltip-content={t(lng, 'app.role.limitation')}
            onClick={async () => {
              await checkLimits((usage) => dispatch(setMyUsage(usage)));
              if (limits[ROUTE_TRX_POLICY].from >= limits[ROUTE_TRX_POLICY].to) {
                setLimitsModal(true);
              } else {
                setOpenAddEdit(true);
              }
            }}
          >
            {!createPolicyPermission && (
              <Suspense fallback={null}>
                <VTooltip id='create-trx-policy' />
              </Suspense>
            )}
            <i className='icon-new-rule' />
            <T>app.create.a.new.rule</T>
          </Button>
        </div>
      </div>
      <GoverananceTable
        type={tab}
        loading={loading}
        tempData={tempData}
        editHadler={editHandlerData}
        viewHandler={viewHandler}
      />
      {openAddEdit && (
        <Suspense fallback={null}>
          <CreateTransactionPolicyModal
            toggleFunction={closeModal}
            wallets={allWallets}
            successCallback={getActiveAndPendingRules}
            handleChooseWallets={setSelectWallets}
            selectWallets={selectWallets}
            approvers={approvers}
            editData={editData}
          />
        </Suspense>
      )}
      {openView && (
        <Suspense fallback={null}>
          <GLViewModal toggleFunction={closeViewModal} viewData={viewData} />
        </Suspense>
      )}
      {limitsModal && (
        <Suspense fallback={null}>
          <LimitsModal toggleFunction={setLimitsModal} modalName='app.policies.limit' />
        </Suspense>
      )}
    </div>
  );
};
export default GovernanceLayer;
