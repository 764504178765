import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { getAssetsByClientId, getVaultsByAssetIdApi } from '../../api/endpoints';
import AssetAccordionTitle from '../../components/Pages/Assets/AssetAccordionTitle/AssetAccordionTitle';
import AssetAccordionDetails from '../../components/Pages/Assets/AssetAccordionDetails/AssetAccordionDetails';
import Accordion from '../../components/Accordion/Accordion';
import { T } from '../../components/T';
import './Assets.css';
import Balance from '../../components/Balance/Balance';
import Skeleton from '../../components/Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';

const Assets = () => {
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState();
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assetVaults, setAssetVaults] = useState();

  const balanceCalculations = useMemo(() => {
    if (assets) {
      return assets?.reduce(
        (acc, currentValue) => {
          return {
            allocatedAmount:
              acc?.allocatedAmount + currentValue?.allocatedAmount * currentValue?.exchangeRate,
            totalAmount: acc?.totalAmount + currentValue?.totalAmount * currentValue?.exchangeRate,
            availableAmount:
              acc?.availableAmount + currentValue?.availableAmount * currentValue?.exchangeRate,
            blockedAmount: acc?.blockedAmount + currentValue?.blockedAmount * currentValue?.exchangeRate,
          };
        },
        {
          availableAmount: 0,
          totalAmount: 0,
          blockedAmount: 0,
          allocatedAmount: 0,
        },
      );
    }
  }, [assets]);

  const getAssetsByAddress = (assetId, network, accordionIndex) => {
    setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: true }));
    getVaultsByAssetIdApi(assetId, network, typeUser)
      .then((res) => setAssetVaults((prev) => ({ ...prev, [accordionIndex]: res?.data?.item })))
      .finally(() => setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: false })));
  };

  const getAssetsByClient = () => {
    setLoading(true);
    getAssetsByClientId(typeUser, { params: { limit: 0 } })
      .then((res) => setAssets(res?.data?.items))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (typeUser) {
      getAssetsByClient();
    }
  }, [typeUser]);

  return (
    <div className={`assets-page-table-wrapper ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
      <div className='asset-page-balance'>
        <Balance
          avable={balanceCalculations?.availableAmount}
          aml={balanceCalculations?.blockedAmount}
          total={balanceCalculations?.totalAmount}
          allocated={balanceCalculations?.allocatedAmount}
        />
      </div>
      <div className='asset-page-header'>
        <div className='first-column asset-accordion-header'>
          <div className='wallet-name'>
            <T>app.assets</T>
          </div>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.available.balance</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.total.balance</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.aml.blocked</T>
        </div>
        <div className='balance asset-accordion-header'>
          <T>app.allocated</T>
        </div>
        <div className='settings asset-accordion-header' />
      </div>
      <div className='asset-page-assets-listing-wrapper'>
        {loading && (
          <div className='loading-full-height-assets'>
            <Skeleton rowHeight={isMobile ? 265 : 104} padding={isMobile ? 20 : 50} />
          </div>
        )}
        {!loading &&
          assets?.map((asset, index) => (
            <Accordion
              key={index}
              open={accordionOpenIndexes?.includes(index)}
              onOpenCallback={(isAccordionOpened) => {
                if (!isAccordionOpened) {
                  setAccordionOpenIndexes((prev) => [...prev, index]);
                  getAssetsByAddress(asset?.id, asset?.blockchain, index);
                } else {
                  setAccordionOpenIndexes((prev) =>
                    prev?.filter((accordionIndex) => accordionIndex !== index),
                  );
                }
              }}
              titleComponent={<AssetAccordionTitle asset={asset} />}
              detailsComponent={
                loadingAssets[index] ? (
                  <div className='team-page wallets wallets-address'>
                    <div className='loading'>
                      <ReactLoading type='spin' color='##020D1C' />
                    </div>
                  </div>
                ) : (
                  <AssetAccordionDetails
                    asset={asset}
                    addressId={asset?.address}
                    blockchain={asset?.blockchain}
                    rows={
                      assetVaults?.[index]?.vaults?.map((vault) => ({
                        ...vault,
                        exchangeRate: assetVaults?.[index]?.exchangeRate,
                        symbol: asset?.symbol,
                        assetData: {
                          allocatedBalance: vault?.allocatedAmount,
                          amount: vault?.totalAmount,
                          availableAmount: vault?.availableAmount,
                          blockedBalance: vault?.blockedAmount,
                        },
                      })) || []
                    }
                    type='vault'
                  />
                )
              }
            />
          ))}
      </div>
      {!loading && assets?.length === 0 && (
        <div className='empty-addresses-label'>
          <div>
            <T>app.no.assets.found</T>
          </div>
        </div>
      )}
    </div>
  );
};
export default Assets;
