import { useSelector } from 'react-redux';
import { T } from '../T';
import GLRow from './GLRow';
import { useMediaQuery } from 'react-responsive';
import Skeleton from '../Skeleton/Skeleton';

const GovernanceTable = ({ type, tempData, editHadler, viewHandler, loading }) => {
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);

  return (
    <div
      className={`table governance-layer-table transaction-policy ${
        trialBannerVisible ? 'trial-banner-visible' : ''
      }`}
    >
      <div className='table-content'>
        <div className='table-row caption'>
          <div className='gov-table-data name'>
            <T>app.rules.name</T>
          </div>
          <div className='gov-table-data amount-limit'>
            <T>app.amount.limit.24.hours</T>
          </div>
          <div className='gov-table-data level'>
            <T>app.level1</T>
          </div>
          <div className='gov-table-data level'>
            <T>app.level2</T>
          </div>
          <div className='gov-table-data wallet-table'>
            <T>app.wallets.applied</T>
          </div>
          <div className='gov-table-data settings' />
        </div>
        {loading && (
          <div className='loading-full-height'>
            <Skeleton rowHeight={isMobile ? 284 : 80} padding={isMobile ? 20 : 50} />
          </div>
        )}
        {!loading &&
          tempData !== undefined &&
          tempData.map((dataItem, key) => {
            return (
              <GLRow
                type={type}
                viewHandler={viewHandler}
                editHadler={editHadler}
                dataItem={dataItem}
                key={key}
              />
            );
          })}
        {!loading && tempData?.length === 0 && type === 'active' && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.gov.layer</T>
            </div>
          </div>
        )}
        {!loading && tempData?.length === 0 && type === 'pending' && (
          <div className='table-row no-active'>
            <div>
              <T>app.no.pending.transaction.policy</T>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GovernanceTable;
