import logo from '../../assets/images/logo.svg';
import underMaintanaceImage from '../../assets/images/under-maintanance.svg';
import underMaintanacePeopleImage from '../../assets/images/under-maintanance-people.svg';
import SocialIcons from '../../components/SocialIcons/SocialIcons';
import './Maintanance.css';

const Maintanance = () => {
  return (
    <div className='maintanance-page'>
      <div className='maintanance-page-container'>
        <img className='maintanance-page-image' src={underMaintanacePeopleImage} />
        <img className='maintanance-page-image-secondary' src={underMaintanaceImage} />
        <div className='maintanance-page-info'>
          <img src={logo} className='maintanance-page-logo' />
          <div className='maintanance-page-header'>We Are Currently Under Maintenance</div>
          <div className='maintanance-page-subtext1'>We'll be back until 10:00 (UTC Time)</div>
          <div className='maintanance-page-subtext2'>
            If you have any inquiries, feel free to contact us: support@vaultody.com
          </div>
        </div>
        <div className='maintanance-page-social'>
          <SocialIcons />
        </div>
      </div>
    </div>
  );
};

export default Maintanance;
